import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { SettingsAttribute } from "../data/settings/SettingsAttribute";
import { appendIfUnique } from "../utilities";
import { useAppQuery } from "./useAppQuery";

interface JobMatchingResponse {
  jobs: {
    jobDefinitionId: string;
    jobName: string;
    numberOfJobCompetencies: number;
    numberOfMinimumJobCompetencies: number;
    numberOfMatchingJobCompetencies: number;
    numberOfMatchingMinimumJobCompetencies: number;
  }[];
}

interface UseJobMatchingProps {
  employee: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
  basePath: string;
}

export const useJobMatching = ({ employee, onSortOrderChange, basePath }: UseJobMatchingProps) => {
  const tableRef = React.useRef(null);
  const history = useHistory();
  const [isOpenCompetencyFilterModal, setIsOpenCompetencyFilterModal] = useState(false);

  const [searchText, setSearchText] = useState("");

  const getInitialQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      competencyIds: searchParams.getAll("competencyIds"),
      search: searchParams.get("search") || "",
      showMinNeeded: searchParams.get("isMinNeeded") === "true"
    };
  };

  const [query, setQuery] = useState(() => getInitialQuery());

  const { data: attributeDefinitionRes, isFetching: isCompetencyFetching } =
    useAppQuery<any>("/AttributeDefinition");

  const attributes = useMemo(() => {
    if (!attributeDefinitionRes?.attributeDefinitions) return [];

    const attrs = attributeDefinitionRes?.attributeDefinitions || [];

    return attrs.map((attr: any) => new SettingsAttribute(attr));
  }, [attributeDefinitionRes]);

  const selectedAttributes = useMemo(() => {
    if (isEmpty(attributes) || isEmpty(query.competencyIds)) return [];

    return query.competencyIds.map((id) => attributes.find((attr: any) => attr.id === id));
  }, [attributes, query.competencyIds]);

  // Build query params
  const queryParams = useMemo(() => {
    if (!employee?.employeeId) return "";

    const params = new URLSearchParams();
    appendIfUnique(params, "employeeId", employee.employeeId);
    query.competencyIds.forEach((id) => {
      appendIfUnique(params, "competencyIds", id);
    });

    return params.toString();
  }, [employee?.employeeId, query.competencyIds]);

  const { data, isLoading, error } = useAppQuery<JobMatchingResponse>(
    `/Profile/JobMatching?${queryParams}`
  );

  // Process and filter data
  const filteredRows = useMemo(() => {
    if (!data || !Array.isArray(data.jobs)) return [];

    let transformedData = data.jobs.map((job: any) => ({
      id: job.jobDefinitionId,
      job: job.jobName,
      minNeeded: job.numberOfMatchingMinimumJobCompetencies,
      minNeededTotal: job.numberOfMinimumJobCompetencies,
      competenciesMet: job.numberOfMatchingJobCompetencies,
      competenciesTotal: job.numberOfJobCompetencies,
      matchingPercentage: Math.round(
        (job.numberOfMatchingJobCompetencies / job.numberOfJobCompetencies) * 100
      ),
      meetsMinimum:
        job.numberOfMatchingMinimumJobCompetencies >= job.numberOfMinimumJobCompetencies,
      //
      allCompetencies: job.numberOfMatchingJobCompetencies / job.numberOfJobCompetencies,
      overallMatching: Math.round(
        (job.numberOfMatchingJobCompetencies / job.numberOfJobCompetencies) * 100
      )
    }));

    // Apply search filter
    if (query.search) {
      transformedData = transformedData.filter((row) =>
        row.job.toLowerCase().includes(query.search.toLowerCase())
      );
    }

    // Apply minimum needed filter
    if (query.showMinNeeded) {
      transformedData = transformedData.filter((row) => row.meetsMinimum);
    }

    return transformedData;
  }, [data, query.search, query.showMinNeeded]);

  const handleSearchChange = (searchValue: string) => {
    setQuery((prev) => ({ ...prev, search: searchValue }));
  };

  const handleMinNeededChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((prev) => ({ ...prev, showMinNeeded: event.target.checked }));
  };

  const handleSortOrderChange = (event: any, value: any) => {
    if (onSortOrderChange) {
      onSortOrderChange(event, value);
    }
  };

  const handleRemoveAttribute = (attributeToRemove: any) => {
    setQuery((prev) => ({
      ...prev,
      competencyIds: query.competencyIds.filter((id) => id !== attributeToRemove.id)
    }));
  };

  const handleClearAll = () => {
    setQuery((prev) => ({ ...prev, competencyIds: [] }));
  };

  const handleRowClick = (row: any) => {
    history.push(`${basePath}/job-matching/${row.id}`);
  };

  const onShowFilterModal = () => {
    setIsOpenCompetencyFilterModal(true);
  };

  const onCloseFilterModal = () => {
    setIsOpenCompetencyFilterModal(false);
  };

  const handleAttributesAdded = (selectedAttributes: any[]) => {
    setQuery((prev) => ({ ...prev, competencyIds: selectedAttributes.map((attr) => attr.id) }));
    onCloseFilterModal();
  };

  const onSearchCompetency = (keyword: string) => {
    setSearchText(keyword);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams();
    query.search && queryParams.append("search", query.search);
    queryParams.append("isMinNeeded", query.showMinNeeded.toString());
    !isEmpty(query.competencyIds) &&
      query.competencyIds.forEach((id) => {
        queryParams.append("competencyIds", id);
      });
    if (!queryParams.size) return;
    history.push(`${basePath}/job-matching?${queryParams}`);
  }, [query, basePath, history]);

  return {
    tableRef,
    search: query.search,
    showMinNeeded: query.showMinNeeded,
    handleMinNeededChange,
    handleSortOrderChange,
    filteredRows,
    isLoading,
    error,
    handleSearchChange,
    selectedAttributes,
    isOpenCompetencyFilterModal,
    onSearchCompetency,
    handleAttributesAdded,
    onShowFilterModal,
    onCloseFilterModal,
    attributes,
    handleRemoveAttribute,
    handleClearAll,
    handleRowClick,
    isCompetencyFetching
  };
};
