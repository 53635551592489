import { Box } from "@mui/material";
import { Table } from "./Table";
// import { Star, Check, AlertTriangle } from "lucide-react";
import { ReactComponent as CheckIcon } from "../../resources/images/icons-check.svg";
import { ReactComponent as StarIcon } from "../../resources/images/icons-star.svg";
import { ReactComponent as WarningIcon } from "../../resources/images/icons-warning.svg";
import { Tooltip } from "../Tooltip";

export class JobCompetenciesTable extends Table {
  static columns = {
    summary: {
      field: "summary",
      label: "Summary",
      visibleToBreakpoint: "sm"
    },
    competency: {
      field: "attributeName",
      label: "JOB COMPETENCY",
      visibleToBreakpoint: "sm"
    },
    category: {
      field: "categories",
      label: "CATEGORY",
      visibleFromBreakpoint: "sm"
    },
    priority: {
      field: "priority",
      label: "PRIORITY",
      visibleFromBreakpoint: "sm"
    },
    status: {
      field: "status",
      label: "",
      visibleFromBreakpoint: "sm"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: JobCompetenciesTable.columns.competency },
      { type: JobCompetenciesTable.columns.category },
      { type: JobCompetenciesTable.columns.priority },
      { type: JobCompetenciesTable.columns.status }
    ],
    noRowsMessage: "No competencies available",
    rowHasAction: null,
    selectable: false
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: [
        {
          field: "competency",
          sort: "asc"
        }
      ]
    };
  }

  getRowId(e: any) {
    return this.tableId + "_" + e.id;
  }

  getCategoryFromAttribute(row: any) {
    if (!row.categories || !Array.isArray(row.categories) || row.categories.length === 0) {
      return "n/a";
    }
    return row.categories.map((category: any) => category.name).join(", ");
  }

  getPriorityType(row: any) {
    if (row.mandatory) return "Compliance";
    if (row.required) return "Required";
    return "Desirable";
  }

  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === JobCompetenciesTable.columns.summary)
          this.columns.push({
            field: "summary",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            filterable: false,
            flex: 1,
            disableReorder: true,
            renderCell: (params: any) => (
              <div
                className={
                  "klayo-table_multilinerow" +
                  (this.props.tree && params.row.isChild === true ? " klayo-table_childattr" : "") +
                  (params.row.isParent && params.rowNode.childrenExpanded
                    ? " klayo-table_rowexpanded"
                    : "")
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div style={{ marginBottom: "4px", fontSize: "16px", fontWeight: "bold" }}>
                    {params.row.attributeName}
                  </div>
                  <div>{this.getPriorityType(params.row)}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "16px"
                  }}
                >
                  {!params.row.missing ? (
                    <CheckIcon className='klayo-attrtable_checkicon' />
                  ) : (
                    <WarningIcon className='klayo-attrtable_warningicon' />
                  )}
                </div>
              </div>
            )
          });
        if (c.type === JobCompetenciesTable.columns.competency)
          this.columns.push({
            field: "attributeName",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 3,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            renderCell: (params: any) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip title={params.row.attributeName} placement='left'>
                  <span>{params.row.attributeName}</span>
                </Tooltip>
                <Tooltip title='This is a minimum competency' placement='right'>
                  {params.row.isMinimum && <StarIcon />}
                </Tooltip>
              </Box>
            ),
            headerClassName: "job-name",
            cellClassName: "job-name"
          });

        if (c.type === JobCompetenciesTable.columns.category)
          this.columns.push({
            field: "categories",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 1,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            valueGetter: (params: any) => this.getCategoryFromAttribute(params.row),
            headerClassName: "categories",
            cellClassName: "categories"
          });

        if (c.type === JobCompetenciesTable.columns.priority)
          this.columns.push({
            field: "priority",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 1,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            valueGetter: (params: any) => this.getPriorityType(params.row),
            headerClassName: "priority",
            cellClassName: "priority"
          });

        if (c.type === JobCompetenciesTable.columns.status)
          this.columns.push({
            field: "status",
            headerName: c.label || c.type.label,
            sortable: false,
            width: 36,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            renderCell: (params: any) => {
              return !params.row.missing ? (
                <Tooltip title='You have this competency' placement='right'>
                  <CheckIcon className='klayo-attrtable_checkicon' />
                </Tooltip>
              ) : (
                <Tooltip title='You are missing this competency' placement='right'>
                  <WarningIcon className='klayo-attrtable_warningicon' />
                </Tooltip>
              );
            },
            headerClassName: "status",
            cellClassName: "status"
          });
      }
    });
  }
}
