import styled from "@emotion/styled";
import React, { useMemo } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { SingleColumnTable } from "../../components/table/SingleColumnTable";
import { TRAINING_ATTRIBUTE_DIALOG_TYPE } from "../../constants";
import { KLAYO_COLORS } from "../../themes";
import { useAppQuery } from "../../hooks/useAppQuery";
import { SimilarJobsTable } from "../../components/table/SimilarJobsTable";
import { useHistory } from "react-router";
import { SectionLoader } from "../../components/SectionLoader";
import { ISimilarJob } from "../../interfaces/Competency";

export const ShowSimilarJobDialog = ({ theme, onClose, row }: any) => {
  const history = useHistory();

  const { data: similarJob, isFetching } = useAppQuery<ISimilarJob>(
    `/JobDefinition/${row?.selectedJob.jobDefinitionId}/SimilarJobs`,
    {
      enabled: !!row?.selectedJob.jobDefinitionId
    }
  );

  const similarJobs = useMemo(() => {
    if (!similarJob) return [];

    return similarJob?.jobs || [];
  }, [similarJob]);
  const handleJobClick = (e: any, job: any) => {
    history.push(`/settings/jobs/edit/${job.jobDefinitionId}`);
    onClose();
  };
  return (
    <StyledTrainingAttributesDialog
      className='klayo__training-attribute-dialog'
      open={true}
      theme={theme}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      actions={[
        {
          label: "Close",
          variant: "filled",
          onClick: onClose
        }
      ]}
    >
      <h1 className='truncate'> Showing similar jobs for {row?.selectedJob.name}</h1>
      {isFetching ? (
        <SectionLoader />
      ) : (
        <SimilarJobsTable
          showFooterRowCount={false}
          pagination={false}
          rowHasAction={true}
          minHeight='56px'
          theme={theme}
          showHeaderFromBreakpoint='md'
          hideFirstLastBorder={true}
          dense={true}
          paginationMode='client'
          columns={[
            { type: SimilarJobsTable.columns.name },
            { type: SimilarJobsTable.columns.similarity }
          ]}
          paper={false}
          onRowClick={handleJobClick}
          rows={similarJobs ? similarJobs : null}
        />
      )}
    </StyledTrainingAttributesDialog>
  );
};

const StyledTrainingAttributesDialog = styled(Dialog)`
  &.klayo__training-attribute-dialog {
    h1 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.5px;
      margin-top: 0px;
      margin-bottom: 32px;
    }

    h2 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 0px;
      margin-bottom: 24px;
    }

    .main-title {
      font-weight: 700;
    }

    .MuiDialogContent-root {
      padding: 40px 40px 0px 40px;
    }

    .MuiPaper-root {
      max-width: 640px;
    }
  }
`;
