import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalConsumer,
  MsalProvider,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import "inter-ui/inter.css";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { msalConfig } from "./common/AuthConfig";
import reportWebVitals from "./common/ReportWebVitals";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import "./resources/css/app.css";
import "./resources/css/colors.css";
import "./resources/css/fonts.css";
import "./resources/css/print.css";
import { AuthLoadingView } from "./views/other/AuthLoadingView";
import { NotLoggedInView } from "./views/other/NotLoggedInView";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/*msalInstance.addEventCallback(message => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
        //Utils.deleteCookie('Impersonate');
        //var domain = new URL(window.app.env.API_URL).hostname;
        //document.cookie = `DemoMode=; expires = Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain};path=/;`; //samesite=none;`;
    }
});*/

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalConsumer>
        {(msalContext) => (
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={(props) => <NotLoggedInView {...props} msal={msalContext} />}
            loadingComponent={AuthLoadingView}
          >
            <AuthenticatedTemplate>
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <SnackbarProvider>
                    <App msal={msalContext} />
                  </SnackbarProvider>
                  <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
                </QueryClientProvider>
              </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <NotLoggedInView msal={msalContext} />
            </UnauthenticatedTemplate>
          </MsalAuthenticationTemplate>
        )}
      </MsalConsumer>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
