import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { Placeholder } from "../../components/Placeholder";
import { SectionLoader } from "../../components/SectionLoader";
import { ProfileTrainingTable } from "../../components/table/ProfileTrainingTable";
import { TRAINING_ATTRIBUTE_DIALOG_TYPE } from "../../constants";
import { UserTrainings } from "../../data/users/UserTrainings";
import { ReactComponent as CertificateIcon } from "../../resources/images/icons-certificate.svg";
import { AbstractProfileSection } from "./AbstractProfileSection";
import { ProfileTrainingCompetenciesDialog } from "./ProfileTrainingCompeteniesDialog";

export class ProfileTrainingSection extends AbstractProfileSection {
  constructor(props: any) {
    super(props);

    this.state = {
      associatedAttributeDetail: null,
      isTrainingAttributesDialogShow: false,
      trainingDialogType: null,
      sortModel: [
        {
          field: "completedDate",
          sort: "desc"
        }
      ],
      trainings: {}
    };
  }

  componentDidMount(): void {
    this.loadTrainings();
  }

  loadTrainings() {
    const { organization, employee } = this.props;

    if (organization?.hasIntegration) {
      this.context.setLoading("training", true);
      UserTrainings.get(this.context, employee.employeeId)
        .then((data: any) => {
          this.setState({ trainings: data });
        })
        .catch((error: any) => {})
        .finally(() => this.context.setLoading("training", false));
    }
  }

  onShowTrainingAttributesDialog(associatedRow: any) {
    // set detail data for dialog
    this.setState({ associatedAttributeDetail: associatedRow });

    this.setState({ isTrainingAttributesDialogShow: true });
    this.setState({ trainingDialogType: TRAINING_ATTRIBUTE_DIALOG_TYPE.DETAIL });
  }

  onCloseTrainingAttributesDialog() {
    this.setState({ isTrainingAttributesDialogShow: false });
    this.setState({ trainingDialogType: null });
  }

  render() {
    const { theme } = this.props;
    const { associatedAttributeDetail, isTrainingAttributesDialogShow, sortModel, trainings } =
      this.state;

    const rows = trainings?.trainings || [];

    if (isEmpty(trainings)) return <SectionLoader />;

    if (!rows.length)
      return (
        <Placeholder
          text={{
            primary: "Nothing to show yet",
            secondary: <div>Any mapped training you complete will appear here.</div>
          }}
          icon={<CertificateIcon />}
          sx={{ height: "100vh", maxHeight: "40vh" }}
        />
      );
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <ProfileTrainingTable
              rows={rows}
              theme={theme}
              showDialog={this.onShowTrainingAttributesDialog.bind(this)}
              sortable={true}
              sortModel={sortModel}
              sortingMode='client'
              selectable={false}
            />
          </Grid>
        </Grid>

        {isTrainingAttributesDialogShow && (
          <ProfileTrainingCompetenciesDialog
            onClose={this.onCloseTrainingAttributesDialog.bind(this)}
            associatedAttributeDetail={associatedAttributeDetail}
            theme={theme}
          />
        )}
      </Box>
    );
  }
}
