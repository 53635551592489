import { Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { debounce, get, isEqual } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { TextField } from "../../components/TextField";
import { Dialog } from "../../components/dialogs/Dialog";
import { MultiAttributeSelector } from "../../components/selectors/MultiAttributeSelector";
import { CREATE_TASK_MODE, PAGE_SIZE_FOR_ADD_EDIT } from "../../constants";
import { Data } from "../../data/Data";
import { SettingsAttributeList } from "../../data/settings/SettingsAttributeList";
export class CreateTaskDialog extends Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      attributes: null,
      selectedSuggestedAttributes: [],
      allSelectedAttributes: [],
      suggestedAttributes: [],
      attributesSearch: null,
      selectedAttributes: [],
      taskName: null,
      originalTaskName: null,
      originalSelectedAttributes: null,
      isAttributeLoading: false
    };

    this.onSearchAttributes = debounce(this.onSearchAttributes.bind(this), 500);
  }

  componentDidMount() {
    const { mode, newTaskName } = this.props;
    this.loadAttributes();
    if (mode === CREATE_TASK_MODE.EDIT) {
      this.loadTaskDetail();
    }
    if (newTaskName) {
      this.setState({ taskName: newTaskName });
    }
  }

  loadAttributes(searchText?: any) {
    this.setState({
      isAttributeLoading: true
    });
    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };
    // get setting attributes data then set to attributes state
    SettingsAttributeList.getWithPaging(this.context, pagingOptions)
      .then((attributes) => {
        this.setState({ attributes, attributesSearch: searchText });
      })
      .catch((error) => {})
      .finally(() =>
        this.setState({
          isAttributeLoading: false
        })
      );
  }

  loadTaskDetail() {
    const { actionTask } = this.props;
    (Data as any)
      .get(this.context, `/Regulation/Task/${actionTask.id}`, { withCredentials: true })
      .then((response: any) => {
        this.setState({
          taskName: get(response, "data.regulationTask.name", ""),
          originalTaskName: get(response, "data.regulationTask.name", ""),
          originalSelectedAttributes: get(response, "data.regulationTask.competencies", [])
        });
        this.setAttributes(get(response, "data.regulationTask.competencies", []));
      })
      .catch((e: any) => {})
      .finally(() => {
        this.context.setLoading("tasks", false);
      });
  }

  onAttributesBlur() {
    const { attributesSearch } = this.state;
    if (attributesSearch) this.loadAttributes();
  }

  onHandleSearchAttributes(searchText: any) {
    this.setState({ attributes: [] });
    this.onSearchAttributes(searchText);
  }

  onSearchAttributes(searchText: any) {
    this.loadAttributes(searchText);
  }

  onSave() {
    const { onSave } = this.props;
    const { taskName, allSelectedAttributes } = this.state;
    if (onSave) onSave(taskName, allSelectedAttributes);
  }

  onAttributesChange(e: any, selectedAttributes: any) {
    const { selectedSuggestedAttributes } = this.state;
    this.setAttributes([...selectedSuggestedAttributes, ...selectedAttributes]);
  }

  setAttributes(allSelectedAttributes: any) {
    allSelectedAttributes.forEach((a: any) => (a.type = null));
    this.setState({ allSelectedAttributes, selectedAttributes: allSelectedAttributes });
  }

  onUpdateName(e: any) {
    this.setState({ taskName: e.target.value });
  }

  isNameValid(name: any) {
    return name && name.length > 2 && name.length < 1000;
  }

  handleValidationText(name: any) {
    if (!name || name.length < 3) return "Please enter a valid name (more than 2 characters)";
    if (!name || name.length > 1000) return "Please enter a valid name (less than 1000 characters)";
  }

  isTaskNameAndAttributesChanged() {
    const { taskName, originalTaskName, allSelectedAttributes, originalSelectedAttributes } =
      this.state;
    return (
      taskName !== originalTaskName ||
      !isEqual(
        allSelectedAttributes.map((a: any) => a.attributeDefinitionId),
        originalSelectedAttributes.map((a: any) => a.attributeDefinitionId)
      )
    );
  }

  render() {
    const { user, theme, onClose, error, onShowNewAttributeDialog, mode, hasNewItem } = this.props;
    const { attributes, selectedAttributes, taskName } = this.state;

    let attributesFiltered = get(attributes, "attributes", []);

    if (get(selectedAttributes, "length", 0) > 0) {
      attributesFiltered = attributesFiltered.filter(
        (attr: any) =>
          !selectedAttributes.some(
            (selected: any) => get(selected, "attributeDefinitionId") === get(attr, "attributeId")
          )
      );
    }

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title={mode === CREATE_TASK_MODE.EDIT ? "Edit task" : "Create task"}
        actions={[
          {
            label: `${mode === CREATE_TASK_MODE.EDIT ? "Save" : "Create"}`,
            primary: true,
            variant: "filled",
            disabled:
              !taskName ||
              taskName.length === 0 ||
              (mode === CREATE_TASK_MODE.EDIT && !this.isTaskNameAndAttributesChanged()),
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}

          <TextField
            value={taskName}
            label='Task name (required)'
            dense={true}
            disabled={user === null}
            autoComplete={false}
            autoFocus={true}
            sx={{ width: "100%" }}
            onChange={this.onUpdateName.bind(this)}
            validationMethod={(e: any, value: any) => this.isNameValid(value)}
            validationText={this.handleValidationText(taskName)}
          />

          <MultiAttributeSelector
            placeHolder='Search and add competencies'
            label='Competencies'
            items={attributesFiltered}
            values={selectedAttributes}
            onNewItemClick={onShowNewAttributeDialog}
            getItemId={(attr: any) => attr.attributeDefinitionId}
            onChange={this.onAttributesChange.bind(this)}
            onSearch={(searchText: any) => {
              this.onHandleSearchAttributes(searchText);
            }}
            onBlur={this.onAttributesBlur.bind(this)}
            hasNewItem={hasNewItem}
            isLoading={this.state.isAttributeLoading}
          />
        </Stack>
      </Dialog>
    );
  }
}
