import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { TeamFilterModel } from "../common/models/TeamFilterModel";
import { Utils } from "../common/Utils";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../constants";
import { AbstractAttribute } from "../data/attributes/AbstractAttribute";
import {
  ITeamTraining,
  ITeamTrainingResponse,
  ITeamTrainingSummaryResponse
} from "../interfaces/Training";
import { useTeamContext } from "../providers/TeamProvider";
import { appendIfUnique } from "../utilities";
import { useAppQuery } from "./useAppQuery";

type Props = {
  filter: TeamFilterModel;
  groupId?: string;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const useTeamTrainingSection = ({
  filter,
  groupId,
  basePath,
  basePathDepth,
  onSortOrderChange,
  sortOrder
}: Props) => {
  const { expandRowIds, setExpandRowIds, keywords, setKeywords } = useTeamContext();
  const [pagination, setPagination] = useState({
    pageNumber: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  });

  const tableRef = useRef<any>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const history = useHistory();
  const location = useLocation();
  const trainingPeopleListDialogRef = useRef<any>();

  const { gapMode, setGapMode } = useTeamContext();

  const isGapMode = useMemo(() => gapMode.trainings, [gapMode.trainings]);

  const filterParams = useMemo(() => {
    if (isEmpty(filter)) return "";

    const params = new URLSearchParams();

    filter.people.forEach((e) => appendIfUnique(params, "EmployeeIds", e.employeeId));
    filter.peopleIds.forEach((id) => appendIfUnique(params, "EmployeeIds", id));
    filter.jobs.forEach((j) => appendIfUnique(params, "Jobs", j));
    filter.locations.forEach((l) => appendIfUnique(params, "Locations", l));
    filter.attributes.forEach((a) =>
      appendIfUnique(params, "AttributeDefinitionIds", a.attributeDefinitionId)
    );
    filter.attributeIds.forEach((id) => appendIfUnique(params, "AttributeDefinitionIds", id));
    if (filter.compliance)
      appendIfUnique(
        params,
        "IsCompliant",
        (filter.compliance === AbstractAttribute.compliance.compliant) as any
      );
    if (filter.attributeType) appendIfUnique(params, "AttributeType", filter.attributeType.index);
    if (filter.expireAfter)
      appendIfUnique(params, "ExpiryDateFrom", Utils.toApiDate(filter.expireAfter) as any);
    if (filter.expireBefore)
      appendIfUnique(params, "ExpiryDateTo", Utils.toApiDate(filter.expireBefore) as any);

    if (pagination.pageNumber) 
      appendIfUnique(params, "pageNumber", pagination.pageNumber + 1);

    if (pagination.pageSize) 
      appendIfUnique(params, "pageSize", pagination.pageSize);
    if (keywords.trainings) 
      appendIfUnique(params, "searchText", keywords.trainings);
    if (isGapMode) 
      appendIfUnique(params, "showGapsOnly", isGapMode);

    if (!isEmpty(sortModel)) {
      sortModel.forEach((model) => {
        appendIfUnique(params, "orderBy", `${model.field} ${model.sort}`);
      });
    }

    return params.toString();
  }, [filter, pagination, keywords.trainings, sortModel, isGapMode]);

  const summaryFilterParams = useMemo(() => {
    if (isEmpty(filter)) return "";

    const params = new URLSearchParams();

    filter.people.forEach((e) => appendIfUnique(params, "EmployeeIds", e.employeeId));
    filter.peopleIds.forEach((id) => appendIfUnique(params, "EmployeeIds", id));
    filter.jobs.forEach((j) => appendIfUnique(params, "Jobs", j));
    filter.locations.forEach((l) => appendIfUnique(params, "Locations", l));
    filter.attributes.forEach((a) =>
      appendIfUnique(params, "AttributeDefinitionIds", a.attributeDefinitionId)
    );
    filter.attributeIds.forEach((id) => appendIfUnique(params, "AttributeDefinitionIds", id));
    if (filter.compliance)
      appendIfUnique(
        params,
        "IsCompliant",
        (filter.compliance === AbstractAttribute.compliance.compliant) as any
      );
    if (filter.attributeType) appendIfUnique(params, "AttributeType", filter.attributeType.index);
    if (filter.expireAfter)
      appendIfUnique(params, "ExpiryDateFrom", Utils.toApiDate(filter.expireAfter) as any);
    if (filter.expireBefore)
      appendIfUnique(params, "ExpiryDateTo", Utils.toApiDate(filter.expireBefore) as any);
    if (isGapMode) 
      appendIfUnique(params, "showGapsOnly", isGapMode);
    return params.toString();
  }, [filter, isGapMode]);

  const { data, isLoading } = useAppQuery<ITeamTrainingResponse>(
    `/${groupId ? `MyGroup/${groupId}` : "Teams"}/Trainings?${filterParams}`
  );
  const { data: trainingSummary, isLoading: isSummaryLoading } =
    useAppQuery<ITeamTrainingSummaryResponse>(
      `/${groupId ? `MyGroup/${groupId}` : "Teams"}/Trainings/summary?${summaryFilterParams}`
    );

  const trainings = useMemo(() => {
    if (!data?.trainings) return [];

    return data?.trainings?.data.map((training) => ({
      ...training,
      employeesCount: training.completedEmployeesCount,
      name: training.integrationAbbreviation
        ? `${training.name} | ${training.integrationAbbreviation}`
        : training.name,
      rowId: training.id
    }));
  }, [data]);

  const classificationsSummary = useMemo(() => {
    if (!trainingSummary?.trainings?.length) return {};

    return trainingSummary?.trainings.reduce(
      (summary: { [key: string]: Partial<ITeamTraining> }, training: ITeamTraining) => {
        if (
          (isGapMode && !training.gapEmployeesCount) ||
          (!isGapMode && !training.completedEmployeesCount)
        ) {
          return summary;
        }

        const trainingName = training.integrationAbbreviation
          ? `${training.name} | ${training.integrationAbbreviation}`
          : training.name;

        summary[training.id] = {
          name: trainingName,
          employeesCount: !isGapMode
            ? training.completedEmployeesCount
            : training.gapEmployeesCount,
          gapEmployeesCount: training.gapEmployeesCount,
          id: training.id,
          type: "training",
          rowId: training.id
        };
        return summary;
      },
      {}
    );
  }, [trainingSummary, isGapMode]);

  const trainingTotal = useMemo(() => data?.trainings.totalCount, [data]);

  const generateTreeTasks = (trainings: ITeamTraining[]) => {
    if (!trainings) return [];

    return trainings.flatMap((training: ITeamTraining) => {
      const taskEntry = {
        ...training,
        rowId: training.id,
        trainingHierarchy: [training.id],
        type: "training",
        employeesCount: training.completedEmployeesCount
      };

      const competencyEntries = training.competencies.map((competency) => ({
        ...competency,
        trainingHierarchy: [training.id, competency.attributeDefinitionId],
        rowId: `${training.id}_${competency.attributeDefinitionId}`,
        name: competency.attributeName,
        id: competency.attributeDefinitionId,
        type: "competency",
        isHiddenPeople: true
      }));

      return [taskEntry, ...competencyEntries];
    });
  };

  const treeTrainings = useMemo(() => generateTreeTasks(trainings), [trainings]);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.trainingHierarchy;

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    // tableRef?.current?.setSortModel(model);
  };

  const handleSortOrderChange = (e: any, sortOrder: any) => {
    onSortModelChange([{ field: "attribute", sort: sortOrder.method }]);
    onSortOrderChange && onSortOrderChange(e, sortOrder);
  };

  const onSwitchToGapMode = (e: ChangeEvent<HTMLInputElement>) => {
    setGapMode({
      ...gapMode,
      trainings: e.target.checked
    });
  };

  const setLocation = (path: any, useNative?: any) => {
    history.push(path + "?" + filter.toUrlParams());

    if (useNative === true) window.history.pushState(null, "", path + "?" + filter.toUrlParams());
    else history.push(path + "?" + filter.toUrlParams());
  };

  const onCloseTrainingPeopleListDialog = () => {
    setLocation(basePath + "/" + "trainings", true);
    if (trainingPeopleListDialogRef.current) trainingPeopleListDialogRef.current.close();
  };

  const onPeopleSelect = (e: any, training: any) => {
    setLocation(basePath + "/trainings/" + training.rowId, true);
    if (trainingPeopleListDialogRef.current)
      trainingPeopleListDialogRef.current.open(training, false, training.type);
  };

  const onGapSelect = (e: any, training: any) => {
    setLocation(basePath + "/trainings/" + training.rowId + "?isGap=true", true);
    if (trainingPeopleListDialogRef.current)
      trainingPeopleListDialogRef.current.open(training, true, training.type);
  };

  const onExpandRowChange = (ids: string[]) => {
    setExpandRowIds({ ...expandRowIds, trainings: ids });
  };

  const onPaginationChange = (models: GridPaginationModel) => {
    setPagination({
      pageNumber: models.page,
      pageSize: models.pageSize
    });
  };

  useEffect(() => {
    if (isEmpty(treeTrainings)) return;

    const pathSplit = location.pathname.split("/");

    const training = treeTrainings.find(
      (training) => training.rowId === pathSplit[basePathDepth + 2]
    );

    const isGap = location.search.includes("isGap=true");

    if (trainingPeopleListDialogRef.current && training)
      trainingPeopleListDialogRef.current.open(training, isGap, training.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeTrainings, basePathDepth]);

  return {
    tableRef,
    sortModel,
    search: keywords.trainings,
    isGapMode,
    classificationsSummary,
    treeTrainings,
    setSearch: (value: string) => setKeywords({ ...keywords, trainings: value }),
    onSortModelChange,
    onSwitchToGapMode,
    isLoading,
    trainingPeopleListDialogRef,
    onPeopleSelect,
    onCloseTrainingPeopleListDialog,
    onGapSelect,
    handleSortOrderChange,
    getTreeDataPath,
    expandRowIds,
    onExpandRowChange,
    trainingTotal,
    onPaginationChange,
    pagination,
    isSummaryLoading
  };
};

export { useTeamTrainingSection };
