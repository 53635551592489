import { Stack } from "@mui/material";
import axiosClient from "../../common/AxiosClient";
import { AbstractDialog } from "../../components/AbstractDialog";
import { Alert } from "../../components/Alert";
import { Dialog } from "../../components/dialogs/Dialog";
import { EmployeeTable } from "../../components/table/EmployeeTable";
import { unFocusTableRowOnClose } from "../../utilities";

export class TrainingPeopleListDialog extends AbstractDialog {
  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      training: this.props.training,
      isGap: false,
      type: "",
      employees: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setLoading("training", !this.props.training);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.training !== prevProps.training) this.setLoading("training", false);
  }

  async onLoadEmployees(trainingId: string, isGap?: boolean, type?: "training" | "competency") {
    try {
      const { groupId } = this.props;

      this.setState({
        isLoading: true
      });

      const url = `/${groupId ? `MyGroup/${groupId}` : "Teams"}/trainings/${type === "competency" ? "competency/" : ""}${trainingId}/${isGap ? "gap" : "completed"}`;

      const res = await axiosClient.get(url);

      const employees = res.data.employees;

      this.setState({
        employees
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }

  open(training: any, isGap?: boolean, type?: "training" | "competency") {
    this.setState({ open: true, training, isGap, type });

    this.onLoadEmployees(training.id, isGap, type);
  }

  onClose() {
    const { onClose } = this.props;

    if (onClose) onClose();

    this.setState({
      training: null,
      isGap: false,
      type: "",
      employees: [],
      isLoading: false
    });

    unFocusTableRowOnClose();
  }

  getTaskDescription() {
    const { isGap, type } = this.state;

    switch (type) {
      case "training":
        return isGap ? (
          <p>The following employees have gaps that could be addressed by this course:</p>
        ) : (
          <p>The following employees have completed this course:</p>
        );

      default:
        return isGap ? (
          <p>The following employees are missing the competency:</p>
        ) : (
          <p>The following employees should have the competency:</p>
        );
    }
  }

  render() {
    const { onEmployeeSelect, theme } = this.props;
    const { open, training, isGap, type, employees, isLoading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onClose.bind(this)}
        theme={theme}
        fullWidth={true}
        title={isGap ? "Gap details" : "People"}
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: this.onClose.bind(this)
          }
        ]}
      >
        {super.renderLoadingBar()}
        <Stack direction='column' spacing={4}>
          <div style={{ fontSize: "16px" }}>
            <Alert showIcon={false} severity='info'>
              <b>{training?.name}</b>
            </Alert>
            {this.getTaskDescription.bind(this)()}
          </div>
          <EmployeeTable
            rows={employees}
            pageSize={50}
            paper={false}
            theme={theme}
            columns={[
              { type: EmployeeTable.columns.profilePicFullName, showRole: false },
              ...(!isGap && type === "competency"
                ? [
                    {
                      type: EmployeeTable.columns.isCompliant,
                      visibleIcon: true,
                      align: "center",
                      headerAlign: "center",
                      visibleHeader: false,
                      sortable: false,
                      width: 100
                    }
                  ]
                : [])
            ]}
            pagination={true}
            rowsPerPageOptions={[50]}
            disableMultipleSelection={true}
            disableSelectionOnClick={true}
            onEmployeeSelect={onEmployeeSelect}
            className='klaro_attrppl_table'
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            border='none'
            style={{
              marginTop: 0
            }}
            isLoading={isLoading}
          />
        </Stack>
      </Dialog>
    );
  }
}
