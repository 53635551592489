import { Data } from "../Data";
import { SettingsAttribute } from "./SettingsAttribute";

export class SettingsAttributeList extends Data {
  static instance: any = null;
  static defaultPageSize = 50;

  attributes: any;
  currentPage: any;
  totalPages: any;
  totalCount: any;
  hasNext: any;
  hasPrevious: any;

  constructor(options: any) {
    const { searchText, pageNumber, pageSize, orderBy, categoryIds, ...otherOptions } = options;

    const apiOptions = {
      api: `/AttributeDefinition/Admin`,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsAttributeList.defaultPageSize}`);
    if (categoryIds && categoryIds.length > 0) {
      if (Array.isArray(categoryIds)) {
        categoryIds.forEach((id: any) => {
          queryParams.push(`categoryIds=${encodeURIComponent(id)}`);
        });
      } else {
        queryParams.push(`categoryIds=${encodeURIComponent(categoryIds)}`);
      }
    }
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.attributes = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data: any) {
    // reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = data.attributeDefinitions;

    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;

    data.attributeDefinitions.data.forEach((attr: any, index: any) =>
      this.attributes.push(new SettingsAttribute(attr))
    );
  }

  get(id: any) {
    return this.attributes.find((l: any) => l.attributeId === id || l.attributeId === id);
  }

  update(attribute: any) {
    const attributes = [...this.attributes];
    const index = attributes.indexOf(this.get(attribute.attributeId));
    const updatedAttr = new SettingsAttribute(attribute);
    if (index !== -1) attributes.splice(index, 1, updatedAttr);
    this.attributes = [...attributes];
    return updatedAttr;
  }

  add(attribute: any) {
    const newAttr = new SettingsAttribute(attribute);
    this.attributes = [...this.attributes];
    this.attributes.push(newAttr);
    return newAttr;
  }

  delete(attribute: any) {
    const attributes = [...this.attributes];
    const index = attributes.indexOf(attribute);
    if (index !== -1) attributes.splice(index, 1);
    this.attributes = [...attributes];
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (SettingsAttributeList.instance && updateCache !== true)
        (resolve as any)(SettingsAttributeList.instance, true);
      else SettingsAttributeList.instance = new SettingsAttributeList({ resolve, reject, context });
    });
  }

  static getWithPaging(context: any, options: any) {
    const { searchText, pageNumber, pageSize, orderBy, categoryIds } = options;

    return new Promise((resolve, reject) => {
      SettingsAttributeList.instance = new SettingsAttributeList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        categoryIds,
        resolve,
        reject,
        context
      });
    });
  }
}
