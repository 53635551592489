import { AppBar, Box, Dialog, DialogContent, Toolbar } from "@mui/material";
import { AppContext } from "../../common/AppContext";
import { AbstractDialog } from "../../components/AbstractDialog";
import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from "../../resources/images/icons-close.svg";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";
import { AddEditAttributeDialog } from "./AddEditAttributeDialog";
import { ProfileLayout } from "./ProfileLayout";
import { UserProfileDialog } from "./UserProfileDialog";

export class EmployeeProfileDialog extends AbstractDialog {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      profile: null,
      showProfileDialog: false
    };
  }

  componentDidMount(): void {
    this.setEmployee(this.props.employee);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.employee !== prevProps.employee) {
      this.setEmployee(this.props.employee);
    }
  }

  onViewDetails() {
    this.setState({ showProfileDialog: true });
  }

  onCloseProfileDialog() {
    this.setState({ showProfileDialog: false });
  }

  setEmployee(employee: any, updateCache?: any) {
    if (!employee) return;

    this.setLoading("profile", true);

    employee
      .loadProfile(this.context, updateCache)
      .then((profile: any) => {
        this.setState({ profile: profile });
      })
      .finally(() => this.setLoading("profile", false));
  }

  reload() {
    this.setEmployee(this.props.employee, true);
  }

  onCloseAddEditAttributeDialog(refresh?: boolean) {
    const { onCloseAttributeDialog } = this.props;

    if (refresh) this.reload();
    onCloseAttributeDialog();
  }

  onCloseDialog() {
    const { onClose } = this.props;

    this.setState({ profile: null }, () => {
      onClose();
    });
  }

  render() {
    const {
      addOrEditCompetencies,
      onClose,
      theme,
      user,
      employee,
      attribute,
      basePath,
      location,
      organization,
      onShowAttributeDetails,
      onCloseAttributeDialog,
      treeMode,
      onChangeViewMode,
      isInstructorViewing,
      isSelfView,
      onEditAttribute,
      addEditAttributeDialogMode,
      onAddAttributeClickCb,
      isTeamView,
      employeeAttribute,
      onAddChangeAction,
      onDeleteAttribute,
      onFeedbackAction,
      onProficiencyChangeAction,
      isNotInstructor
    } = this.props;
    const { profile, showProfileDialog } = this.state;

    if (!employee) return null;

    return (
      <Dialog
        open={!!employee}
        className='klaro_employeeprofile'
        fullScreen={true}
        onClose={this.onCloseDialog.bind(this)}
        maxWidth='xs'
        hideBackdrop
        transitionDuration={0}
      >
        {super.renderLoadingBar()}
        <AppBar className='klaro_employeeprofile-appbar' elevation={0}>
          <Toolbar>
            <Logo className='klaro-appbar_logo' />
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box>
              <Button
                autoFocus
                label='Close'
                variant='outlined'
                size='md'
                startIcon={<CloseIcon />}
                onClick={this.onCloseDialog.bind(this)}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            marginTop: { xs: "56px", md: "64px" },
            padding: { xs: "40px 16px", md: "40px 32px" },
            backgroundColor: "#F8F8FE"
          }}
        >
          <Box sx={{ maxWidth: { xs: "auto", lg: "1040px", xl: "1440px" }, margin: "0 auto" }}>
            <ProfileLayout
              organization={organization}
              user={user}
              myView={isSelfView}
              employee={employee}
              theme={theme}
              profile={profile}
              basePath={basePath}
              location={location}
              treeMode={treeMode}
              isInstructorViewing={isInstructorViewing}
              onChangeViewMode={onChangeViewMode}
              readOnly={!isInstructorViewing && !isSelfView}
              onShowAttributeDetails={onShowAttributeDetails}
              onViewDetails={this.onViewDetails.bind(this)}
              onEditAttribute={onEditAttribute}
              onAddAttributeClickCb={onAddAttributeClickCb}
              onAddChangeAction={onAddChangeAction}
              onDeleteAttribute={onDeleteAttribute}
              onFeedbackAction={onFeedbackAction}
              onProficiencyChangeAction={onProficiencyChangeAction}
              isTeamView={isTeamView}
            />
          </Box>

          {showProfileDialog && (
            <UserProfileDialog
              user={employee}
              employee={employee}
              theme={theme}
              profile={profile}
              onClose={this.onCloseProfileDialog.bind(this)}
              readOnly={true}
            />
          )}

          {/* used for company */}
          {attribute && profile && !isTeamView && (
            <AddEditAttributeDialog
              organization={organization}
              user={user}
              employee={employee}
              theme={theme}
              profile={profile}
              attributeId={attribute}
              readOnly={true}
              onClose={this.onCloseAddEditAttributeDialog.bind(this)}
              mode={AddEditAttributeDialog.mode.details}
            />
          )}

          {/* used for team */}
          {addOrEditCompetencies && profile && (isSelfView || isNotInstructor) && isTeamView && (
            <AddEditAttributeDialog
              organization={organization}
              user={user}
              employee={employee}
              theme={theme}
              profile={profile}
              attributeId={attribute}
              attribute={employeeAttribute}
              readOnly={
                addEditAttributeDialogMode.hash === AddEditAttributeDialog.mode.details.hash
              }
              onClose={this.onCloseAddEditAttributeDialog.bind(this)}
              mode={
                !isSelfView && isNotInstructor
                  ? AddEditAttributeDialog.mode.details
                  : addEditAttributeDialogMode
              }
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
