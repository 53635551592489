import { Autocomplete, AutocompleteChangeReason } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { Component } from "react";
import { ReactComponent as DropIcon } from "../../resources/images/icons-chevron-double.svg";
import { ReactComponent as RemoveIcon } from "../../resources/images/icons-remove.svg";
import { TextField } from "../TextField";

export class AttributeSelector extends Component<any, any> {
  static defaultProps = {
    allowSelection: true,
    label: "Competencies",
    floatLabel: true,
    allowMultiple: false,
    showExistingEmployeeAttributes: true
  };

  ref: any;

  constructor(props: any) {
    super(props);

    this.state = {
      alreadyHasAttribute: false,
      forceBlur: false
    };

    this.ref = React.createRef();
  }

  onChange(e: any, attribute: any, reason: AutocompleteChangeReason) {
    const { employeeAttributes, onChange } = this.props;

    if (reason === "removeOption" && e?.type !== "click") return;

    //this.ref.current.setState({focus: false});
    this.setState({ forceBlur: true });

    this.setState(
      {
        alreadyHasAttribute:
          employeeAttributes &&
          attribute &&
          employeeAttributes?.hasDefinition(attribute.attributeDefinitionId)
      },
      () => {
        onChange(e, attribute, this.state.alreadyHasAttribute);
      }
    );
  }

  renderOption(props: any, option: any, state: any) {
    const matches = match(option.attribute, state.inputValue);
    const parts = parse(option.attribute, matches);

    return (
      <li {...props}>
        {parts.length === 1
          ? option.attribute
          : parts.map((part, index) => (
              <span
                key={index}
                className={
                  "klayo-selector_itempart" +
                  (part.highlight === true ? " klayo-selector_highlight" : "")
                }
              >
                {part.text}
              </span>
            ))}
      </li>
    );
  }

  onBlur(e: any) {
    const { forceBlur } = this.state;
    if (forceBlur) this.setState({ forceBlur: false });
  }

  render() {
    const { attribute } = this.props;
    const {
      definitions,
      allowSelection,
      label,
      floatLabel,
      allowMultiple,
      sx,
      placeholder,
      focusPlaceholder,
      employeeAttributes,
      showExistingEmployeeAttributes
    } = this.props;
    const { alreadyHasAttribute, forceBlur } = this.state;

    const inputLabelProps: any = {};
    if (floatLabel) inputLabelProps.shrink = true;

    return (
      <Autocomplete
        id='klayo-attributeselector'
        className='klayo-attributeselector klayo-selector'
        options={
          definitions
            ? showExistingEmployeeAttributes
              ? definitions.attributes
              : definitions.attributes.filter(
                  (a: any) => !employeeAttributes?.hasDefinition(a.attributeDefinitionId)
                )
            : attribute
              ? [attribute]
              : []
        }
        value={attribute}
        getOptionLabel={(option) => {
          return option.attribute ? option.attribute : option.name;
        }}
        renderOption={this.renderOption.bind(this)}
        disabled={!allowSelection}
        multiple={allowMultiple}
        popupIcon={<DropIcon />}
        clearIcon={<RemoveIcon />}
        handleHomeEndKeys
        ChipProps={{
          deleteIcon: <RemoveIcon />
        }}
        renderInput={(params) => {
          //params.InputProps.endAdornment.props.children[1] = {};
          //IconComponent={(props) => (<ExpandMoreOutlinedIcon {...props}/>)}

          return (
            <TextField
              {...params}
              forceBlur={forceBlur}
              label={label}
              placeholder={placeholder || "Search"}
              focusPlaceholder={focusPlaceholder}
              onBlur={this.onBlur.bind(this)}
              disabled={!allowSelection}
              error={alreadyHasAttribute}
              helperText={alreadyHasAttribute ? "You already have this attribute" : null}
              sx={{ width: "100%" }}
              endAdornment={params.InputProps.endAdornment}
            />
          );
        }}
        onChange={this.onChange.bind(this)}
        sx={sx}
      />
    );
  }
}
