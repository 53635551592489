import { Box, Fade, LinearProgress } from "@mui/material";
import * as d3 from "d3";
import { isEqual } from "lodash";
import React from "react";
import { Utils } from "../../common/Utils";
import { AbstractChart } from "./AbstractChart";

export class TrainingBubbleChart extends AbstractChart {
  static defaultProps = {
    ...AbstractChart.defaultProps,
    height: 270,
    bubbleColor: "#4d5ef7",
    bubbleTextColor: "white",
    bubbleSizeMax: { xs: 56, sm: 56, md: 56, lg: 56, xl: 56 },
    top: null,
    maxFontSize: 16
  };
  svgRef: any;
  tooltip: any;
  node: any;
  text: any;
  svg: any;
  simulation: any;

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state
    };

    this.svgRef = React.createRef();
  }

  onBubbleMouseOver(e: any, d: any) {
    const { bubbleColor, bubbleTextColor } = this.props;
    this.tooltip.style("opacity", 1);

    this.tooltip.html(
      '<div><div class="klayo_bubblechart_tooltip_attribute">' +
        d.name +
        '</div><div class="klayo_bubblechart_tooltip_employees">Employees: ' +
        d.attribute.employeesCount +
        "</div></div>"
    );

    this.node.style("fill", (d2: any) => (d === d2 ? "white" : bubbleColor));
    this.text.style("color", (d2: any) => (d === d2 ? "#333" : bubbleTextColor));
  }

  onBubbleMouseMove(e: any, d: any) {
    // this.tooltip.style("left", (d3.pointer(e)[0] - 20) + "px")
    //     .style("top", (d3.pointer(e)[1]) + "px");

    this.tooltip.style("left", e.clientX + 10 + "px").style("top", e.clientY + 10 + "px");
  }

  onBubbleMouseLeave(e: any, d: any) {
    const { bubbleColor, bubbleTextColor } = this.props;
    this.tooltip.style("opacity", 0);

    this.node.style("fill", bubbleColor);
    this.text.style("color", bubbleTextColor);
  }

  onBubbleClick(e: any, d: any) {
    const { onAttributeSelect } = this.props;
    onAttributeSelect(null, d.attribute);
  }

  componentDidMount() {
    super.componentDidMount();

    this.updateDimensions((width: any, height: any) => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { width, height } = this.state;
    const { attributes, bubbleColor, bubbleTextColor } = this.props;

    // Only render chart if width, height, or attributes have changed
    if (
      prevState.width !== width ||
      prevState.height !== height ||
      !isEqual(prevProps.attributes, attributes) ||
      prevProps.bubbleColor !== bubbleColor ||
      prevProps.bubbleTextColor !== bubbleTextColor
    ) {
      if (this.tooltip) this.tooltip.remove();
      if (this.svg) this.svg.selectAll("*").remove();
      this.renderChart();
    }
  }

  renderChart() {
    if (!this.svgRef.current) return;

    const {
      theme,
      top,
      attributes,
      maxFontSize,
      onAttributeSelect,
      bubbleColor,
      bubbleTextColor,
      bubbleSizeMax
    } = this.props;
    const { width, height } = this.state;
    const data: any = [];

    const currentBreakpoint = theme ? theme.getCurrentBreakpoint()[0] : "lg";

    if (attributes) {
      let items = Object.values(attributes);
      if (top) {
        items = Object.values(attributes).sort(
          (a: any, b: any) => (a.employeesCount < b.employeesCount) as any
        );
        items = items.slice(0, top);
      }
      //data.sort(() => 0.5 - Math.random());

      const counts = Object.values(items).map((a: any) => a.employeesCount);
      const max = Math.max(...counts);
      const min = Math.min(...counts);

      Object.values(items).forEach((a: any) => {
        data.push({
          name: a.name,
          attribute: a,
          value: a.employeesCount
        });
      });

      //data.sort(() => 0.5 - Math.random());

      // Size scale for countries
      const size = d3
        .scaleLinear()
        .domain([top ? min : 0, max])
        .range([top ? 32 : 7, bubbleSizeMax[currentBreakpoint]]); // circle will be between 7 and 55 px wide

      const xScale = d3.scaleLinear().domain([min, max]).range([0, width]);

      this.tooltip = d3
        .select(this.containerRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "klayo_bubblechart_tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px");

      this.svg = d3.select(this.svgRef.current);

      this.node = this.svg
        .append("g")
        .selectAll("circle")
        .data(data)
        .join("circle")
        .attr("class", "node")
        .attr("r", (d: any) => size(d.value))
        .attr("cx", width / 2)
        .attr("cy", height / 2)
        .style("fill", (d: any) => bubbleColor)
        .style("fill-opacity", 0.9)
        .style("stroke", (d: any) => bubbleColor)
        .style("stroke-width", 2)
        .style("cursor", "pointer")
        .on("mouseover", this.onBubbleMouseOver.bind(this))
        .on("mousemove", this.onBubbleMouseMove.bind(this))
        .on("click", this.onBubbleClick.bind(this))
        .on("mouseleave", this.onBubbleMouseLeave.bind(this))
        .call(d3.drag());

      this.text = this.svg
        .append("g")
        .selectAll("foreignObject")
        .data(data)
        .enter()
        .append("foreignObject")
        .attr(
          "class",
          (d: any) =>
            "klayo_bubblechart_bubble klayo_bubblechart_bubble" +
            Math.ceil(size(d.value) / 10.0) * 10
        )
        .attr("width", (d: any) => size(d.value) * 2 + "px")
        .attr("height", (d: any) => size(d.value) * 2 + "px")
        .style("font-size", (d: any) => Math.min(size(d.value) / 3, maxFontSize) + "px")
        .style(
          "transform",
          (d: any) =>
            "translate(-" +
            parseInt(size(d.value) as any) +
            "px, -" +
            parseInt(size(d.value) as any) +
            "px)"
        )
        .style("pointer-events", "none")
        .style("color", (d: any) => bubbleTextColor)
        .style("opacity", (d: any) => (size(d.value) > 20 ? 1 : 0))
        .html((d: any) =>
          top
            ? '<div class="klayo_bubblechart_bubble_inner"><div class="klayo_bubblechart_bubblelabel" style="display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">' +
              Utils.truncate(d.name, 60) +
              "</div></div>"
            : '<div class="klayo_bubblechart_bubble_inner"><div class="klayo_bubblechart_bubblelabel" style="line-height:' +
              parseInt(
                Math.min(size(d.value) / 3 + 1, maxFontSize + 1) +
                  "px;height:" +
                  parseInt(Math.min(size(d.value) / 3, maxFontSize) as any) * 2 +
                  1
              ) +
              'px;">' +
              Utils.truncate(d.name, 15) +
              "</div></div>"
        );

      this.simulation = d3
        .forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(5))
        .force(
          "center",
          d3
            .forceCenter()
            .x(width / 2)
            .y(height / 2)
        ) // Attraction to the center of the svg area
        .force(
          "collide",
          d3
            .forceCollide()
            .strength(0.2)
            .radius((d: any) => size(d.value) + 3)
            .iterations(1)
        ) // Force that avoids circle overlapping*/
        .force(
          "y",
          d3.forceY().y((d: any) => {
            if (theme && theme.isBreakpointUp("sm")) return 0;
            return d.y;
          })
        );

      this.simulation.nodes(data).on("tick", (d: any) => {
        for (let i = 0; i < 10; i++) this.simulation.tick();

        this.node.attr("cx", (d: any) => d.x).attr("cy", (d: any) => d.y);

        this.text.attr("x", (d: any) => d.x).attr("y", (d: any) => d.y);
      });
    }
  }

  render() {
    const { sx, isLoading } = this.props;
    const { width, height } = this.state;

    const svgWidth = width ? width - 20 : null;
    const svgHeight = height ? height - 50 : null;

    return (
      <Box ref={this.containerRef} className='klayo_bubblechart' sx={sx}>
        <Fade in={isLoading === true}>
          <LinearProgress className='klayo-loadingbar' />
        </Fade>
        <div style={{ overflow: "hidden", height: "100%", display: "flex", alignItems: "center" }}>
          <svg
            ref={this.svgRef}
            viewBox={"0 0 " + width + " " + height}
            style={{
              width: svgWidth as any,
              height: svgHeight as any,
              overflow: "visible"
            }}
          ></svg>
        </div>
      </Box>
    );
  }
}
