import { Stack } from "@mui/material";
import { ConfirmationDialog } from "klayowebshared";
import { cloneDeep } from "lodash";
import React from "react";
import { Route, Switch } from "react-router-dom";
import axiosClient from "../../common/AxiosClient";
import { ErrorModel } from "../../common/models/ErrorModel";
import { TeamFilterModel } from "../../common/models/TeamFilterModel";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { PillTabs } from "../../components/PillTabs";
import { Placeholder } from "../../components/Placeholder";
import { SortSelector } from "../../components/selectors/SortSelector";
import { Snackbar } from "../../components/Snackbar";
import { TeamFilterChips } from "../../components/TeamFilterChips";
import { TeamFilterPanel } from "../../components/TeamFilterPanel";
import { ViewHeading } from "../../components/typography/ViewHeading";
import { View } from "../../components/View";
import { AttributeDefinitionList } from "../../data/attributes/AttributeDefinitionList";
import { Organization } from "../../data/Organization";
import { TeamData } from "../../data/users/TeamData";
import { TeamDataExport } from "../../data/users/TeamDataExport";
import { TeamProvider } from "../../providers/TeamProvider";
import { ReactComponent as FlterIcon } from "../../resources/images/icons-filter.svg";
import { ReactComponent as UsersIcon } from "../../resources/images/icons-users-1.svg";
import { AbstractView } from "../AbstractPage";
import { DeleteConfirmationDialog } from "../other/DeleteConfirmationDialog";
import { AddEditAttributeDialog } from "../profile/AddEditAttributeDialog";
import { EmployeeProfileDialog } from "../profile/EmployeeProfileDialog";
import { InstructorAddEditAttributeDialog } from "./InstructorAddEditAttributeDialog";
import { JobClassiciationsDialog } from "./JobClassiciationsDialog";
import { TeamAttributesSection } from "./TeamAttributesSection";
import { TeamPeopleSection } from "./TeamPeopleSection";
import { TeamRegulationSection } from "./TeamRegulationSection";
import { TeamTasksSection } from "./TeamTasksSection";
import { TeamTrainingSection } from "./TeamTrainingSection";

export class Team extends AbstractView {
  static defaultProps = {
    groupMode: false,
    basePath: "/team",
    basePathDepth: 1
  };

  static subPages: Record<string, any> = {
    people: {
      name: "People",
      slug: "",
      paths: ["/", "/:id"],
      component: TeamPeopleSection,
      hasAccess: (user: any) =>
        user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin),
      isDefault: () => true,
      order: 1
    },
    competencies: {
      name: "Competencies",
      slug: "competencies",
      paths: ["/competencies", "/competencies/:id"],
      component: TeamAttributesSection,
      hasAccess: (user: any) =>
        user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin),
      order: 5
    }
  };

  filterPanelRef: any;
  attrPeopleListDialog: any;
  definitiomDialog: any;
  employeeRef: any;
  setAttrPeopleListDialogRef: (attrPeopleListDialog: any) => void;
  setDefinitiomDialogRef: (definitiomDialog: any) => void;
  setEmployeeRef: (ref: any) => void;
  selectedEmployee: any;
  filterButton: any;
  prevPath: any;

  constructor(props: any, context: any, page: any) {
    super(
      props,
      page ?? {
        name: "My team",
        slug: "team",
        paths: ["/team"],
        label: "My team",
        hasAccess: (user: any, organization: any) =>
          user &&
          (user.isTeamLead || user.isOrganizationAdmin) &&
          (!organization || (organization && organization.status !== Organization.status.inactive)),
        admin: true,
        ref: React.createRef()
      }
    );

    this.state = {
      filterModel: TeamFilterModel.fromUrlParams(props.location.search),
      teamList: null,
      definitions: null,
      filterError: null,
      showExportDialog: false,
      sortOrder: SortSelector.sort.ascending,
      basePath: this.getBasePath(),
      loading: false,
      successMessage: null,
      addEditAttributeDialogMode: AddEditAttributeDialog.mode.details,
      editAttribute: null
    };

    this.filterPanelRef = React.createRef();

    this.setAttrPeopleListDialogRef = (attrPeopleListDialog: any) => {
      this.attrPeopleListDialog = attrPeopleListDialog;
    };

    this.setDefinitiomDialogRef = (definitiomDialog: any) => {
      this.definitiomDialog = definitiomDialog;
    };

    this.setEmployeeRef = (ref: any) => {
      this.employeeRef = ref;
    };

    window.onpopstate = (e) => this.parseLocationParams();
  }

  getBasePath() {
    return this.props.basePath;
  }

  componentDidMount() {
    super.componentDidMount();

    //this.context.setLoading('definitions', true);
    AttributeDefinitionList.get(this.context)
      .then((list) => {
        this.setState({ definitions: list });
      })
      .catch((error) => {
        //Todo: handle error
        //alert('error 4553565: ' + error);
      })
      .finally(() => this.context.setLoading("definitions", false));
  }

  componentDidUpdate(prevProps: any, prevState?: any): void {
    super.componentDidUpdate(prevProps, prevState);
  }

  getActiveTab() {
    const { location, basePathDepth } = this.props;

    const pathSplit = location.pathname.split("/");

    if (pathSplit.length > basePathDepth + 1) {
      if (["competencies", "gaps"].includes(pathSplit[basePathDepth + 1])) {
        return "competencies";
      }

      if (["tasks", "regulations", "trainings"].includes(pathSplit[basePathDepth + 1])) {
        return pathSplit[basePathDepth + 1];
      }

      return "";
    }

    return "";
  }

  parseLocationParams() {
    const { location, basePathDepth } = this.props;

    const pathSplit = location.pathname.split("/");
    const activeTab = this.getActiveTab();

    const teamList = this.state.teamList;
    if (teamList) {
      if (activeTab === "") {
        this.selectedEmployee = teamList.getEmployee(pathSplit[basePathDepth + 1]);
        if (this.definitiomDialog)
          this.definitiomDialog.open(pathSplit[basePathDepth + 1] === "classifications");
      } else if (!["tasks", "regulations", "trainings"].includes(activeTab)) {
        if (pathSplit.length === basePathDepth + 3) {
          const attr = teamList["attributes"]?.getAttribute(pathSplit[basePathDepth + 2]);

          const isGap = location.search.includes("isGap=true");

          if (this.attrPeopleListDialog && attr) this.attrPeopleListDialog.open(attr, isGap);
        } else if (this.attrPeopleListDialog) this.attrPeopleListDialog.close();
      }
    }
  }

  onHasUser(user: any) {
    if (!this.verifyAccess()) return;
    this.loadDetails();
  }

  openFilterPanel(e: any) {
    this.filterButton = e.target;
    if (this.filterPanelRef) this.filterPanelRef.current.open(e.target);
  }

  onFilterPanelClose(e: any) {
    if (this.filterPanelRef) this.filterPanelRef.current.close();
  }

  onFilterPanelApply(e: any, filterModel: any) {
    if (this.filterPanelRef) this.filterPanelRef.current.close();
    this.setState({ filterModel: filterModel }, () => this.filter());
  }

  onFilterChipsChange(e: any, filterModel: any) {
    this.setState({ filterModel: filterModel }, () => this.filter());
  }

  filter() {
    const { user, groupMode } = this.props;
    const { groupId, filterModel } = this.state;
    if (user && !((!groupMode && user.isTeamLead) || (groupMode && user.hasGroup))) return;

    if (this.filterPanelRef) this.filterPanelRef.current.setFilterModel(filterModel);

    //this.setState({ filterError: null });
    //if(filterModel && filterModel.isFiltered())
    this.updateUrlParams();

    this.loadDetails();
  }

  async loadDetails() {
    const { groupId } = this.state;

    try {
      // Set initial loading states
      this.context.setLoading("loadingTeam", true);
      this.setState({
        loading: true,
        teamList: null,
        filterError: null
      });

      // Fetch data
      const teamList = await TeamData.get(this.context, this.state.filterModel, groupId);

      // await (() => {
      //   return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
      // })();

      this.updateFilterModelObjects(teamList);
    } catch (error) {
      this.setState({
        filterError: ErrorModel.parseServerError(error),
        teamList: null
      });
    } finally {
      this.context.setLoading("loadingTeam", false);
      this.setState({
        loading: false
      });
    }
  }

  updateUrlParams() {
    const { history, location } = this.props;
    history.push(location.pathname + "?" + this.state.filterModel.toUrlParams());
  }

  updateFilterModelObjects(teamList: any) {
    const updatedFilterModel = this.state.filterModel.clone();

    let updated = false;
    updatedFilterModel.peopleIds.forEach((id: any) => {
      const employee = teamList.getEmployee(id);
      const existedEmployee = updatedFilterModel.people.find((emp: any) => emp.employeeId === id);
      if (employee && !existedEmployee) updatedFilterModel.people.push(employee);
      updated = true;
    });
    updatedFilterModel.peopleIds = [];

    updatedFilterModel.attributeIds.forEach((id: any) => {
      const attribute = this.state.definitions.getAttribute(id);

      const existedAttribute = updatedFilterModel.attributes.find(
        (attribute: any) => attribute.attributeDefinitionId === id
      );

      if (attribute && !existedAttribute) {
        updatedFilterModel.attributes.push(attribute);
      }
      updated = true;
    });
    updatedFilterModel.attributeIds = [];

    let updateState: any = { teamList };
    if (updated) {
      updateState = { ...updateState, filterModel: updatedFilterModel };
    }

    this.setState({ teamList }, () => this.parseLocationParams());
    //this.setState(updateState);

    /*this.setState(updateState, () => this.parseLocationParams());*/
  }

  onCloseAttributePeopleListDialog() {
    const { basePath } = this.state;

    const activeTab = this.getActiveTab();

    this.setLocation(activeTab === "" ? basePath : basePath + "/" + activeTab, true);
    if (this.attrPeopleListDialog) this.attrPeopleListDialog.close();
  }

  onEmployeeSelect(e: any, employee: any) {
    const { location } = this.props;
    const { basePath } = this.state;
    this.prevPath = location.pathname + location.search;
    this.setLocation(basePath + "/" + employee.employeeId);
  }

  onShowEmployeeAttributeDetails(e: any, attr: any) {
    const { basePath } = this.state;
    const employeeId = this.getEmployeeIdFromPath();
    this.setLocation(basePath + "/" + employeeId + "/competencies/" + attr.employeeAttributeId);
  }

  onCloseEmployeeAttributeDetails(e: any, attr: any) {
    this.props.history.push(this.props.location.pathname);
    this.setState({ editAttribute: null });
  }

  onCloseEmployeeProfileDialog() {
    const { basePath } = this.state;
    this.setLocation(this.prevPath ? this.prevPath : basePath);
  }

  onCloseClassificationsDialog() {
    const { basePath } = this.state;
    this.setLocation(this.prevPath ? this.prevPath : basePath);
    if (this.definitiomDialog) this.definitiomDialog.close();
  }

  onViewAllClassifications(e: any, employee: any) {
    const { basePath } = this.state;
    this.setLocation(basePath + "/classifications");
    if (this.definitiomDialog) this.definitiomDialog.open();
  }

  getEmployeeIdFromPath() {
    const { location, basePathDepth } = this.props;
    const pathSplit = location.pathname.split("/");
    return pathSplit.length >= basePathDepth + 2 ? pathSplit[basePathDepth + 1] : null;
  }

  getAttributeIdFromPath() {
    const { location, basePathDepth } = this.props;
    const pathSplit = location.pathname.split("/");
    return pathSplit.length === basePathDepth + 3 ? pathSplit[basePathDepth + 2] : null;
  }

  setLocation(path: any, useNative?: any) {
    const { history } = this.props;
    const { filterModel } = this.state;

    if (useNative === true)
      window.history.pushState(null, "", path + "?" + filterModel.toUrlParams());
    else history.push(path + "?" + filterModel.toUrlParams());
  }

  onExport(e: any) {
    const { filterModel, groupId } = this.state;

    if (this.filterPanelRef) this.filterPanelRef.current.setFilterModel(filterModel);

    this.setState({ filterError: null });
    this.updateUrlParams();
    this.context.setLoading("export", true);

    TeamDataExport.get(this.context, filterModel, groupId)
      .then((teamList) => {
        this.setState({ showExportDialog: false });
      })
      .catch((e) => {
        this.setState({ filterError: ErrorModel.parseServerError(e) });
      })
      .finally(() => this.context.setLoading("export", false));
  }

  onShowExportDialog(e: any) {
    this.setState({ showExportDialog: true });
  }

  onCancelExportDialog(e: any) {
    this.setState({ showExportDialog: false });
  }

  /*onExport(e) {
        const { onExport } = this.props;
        this.setState({ showExportDialog: false });
        if (onExport) onExport(e);
    }*/

  onSortOrderChange(e: any, sortOrder: any) {
    this.setState({ sortOrder });
  }

  onCloseAddEditAttributeDialog(refresh: any, mode: any) {
    this.props.history.push(this.props.location.pathname);
    this.setState({ editAttribute: null });
    if (refresh && this.employeeRef) {
      this.employeeRef.reload();
    }
    if (mode) {
      this.setState({ successMessage: mode === "add" ? "Competency added" : "Competency edited" });
    }
    const { onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Employee attribute");
  }

  onSnackBarClose() {
    this.setState({ successMessage: null, error: null });
  }

  onEditEmployeeAttribute(e: any, attr: any) {
    if (attr?.isParent) return;
    this.setState({ editAttribute: attr });

    this.props.history.push(this.props.location.pathname + "#edit");
  }
  onDeleteAttribute(e: any, attr: any) {
    this.setState({ deletingAttribute: attr });
  }
  onAddAttributeClickCb() {
    this.setState({ addEditAttributeDialogMode: AddEditAttributeDialog.mode.add });
  }

  onFeedbackAction(e: any, attr: any) {
    this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.feedback);
  }

  onProficiencyChangeAction(e: any, attr: any) {
    this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.proficiencyChange);
  }

  onShowAttributeDetails(e: any, attr: any) {
    if (attr.isNeedsFeedback()) this.onFeedbackAction(e, attr);
    else this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.details);
  }

  onAddChangeAction(e: any, attr: any) {
    this.openAddEditAttributeDialog(e, attr, AddEditAttributeDialog.mode.add);
  }

  openAddEditAttributeDialog(e: any, attribute: any, mode: any) {
    this.setState({ editAttribute: attribute, addEditAttributeDialogMode: mode }, () => {
      window.location = (this.props.location.pathname + "#" + mode.hash) as any;
    });
  }

  closeDeleteDialog() {
    this.setState({ deletingAttribute: null, deleteAttributeError: null });
  }

  deleteAttribute() {
    const { user } = this.props;
    const { deletingAttribute } = this.state;

    axiosClient
      .delete("/EmployeeAttribute", {
        data: {
          employeeAttributeId: deletingAttribute.employeeAttributeId
        }
      })
      .then((response) => {
        user.profile.removeAttribute(deletingAttribute);
        this.setState({ deletingAttribute: null, deleteAttributeError: null });
        (this as any).reload();
      })
      .catch((e) => {
        this.setState({ deleteAttributeError: ErrorModel.parseServerError(e) });
      });
  }

  getActivePages(organization: Organization) {
    if (!organization) return Team.subPages;

    const pages = cloneDeep(Team.subPages);

    if (organization.hasRegulations) {
      pages.regulations = {
        name: "Regulations",
        slug: "regulations",
        paths: ["/regulations", "/regulations/:id"],
        component: TeamRegulationSection,
        hasAccess: (user: any) => {
          return user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin);
        },
        order: 2
      };
    }

    if (organization.hasRegulationTasks) {
      pages.tasks = {
        name: "Tasks",
        slug: "tasks",
        paths: ["/tasks", "/tasks/:id"],
        component: TeamTasksSection,
        hasAccess: (user: any) => {
          return user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin);
        },
        order: 3
      };
    }

    if (organization.hasIntegration) {
      pages.trainings = {
        name: "Training",
        slug: "trainings",
        paths: ["/trainings", "/trainings/:id"],
        component: TeamTrainingSection,
        hasAccess: (user: any) => {
          return user && (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin);
        },
        order: 4
      };
    }

    return Object.fromEntries(Object.entries(pages).sort(([, a], [, b]) => a.order - b.order));
  }

  render() {
    if (!this.verifyAccess()) return this.renderAccessDenied();

    const {
      organization,
      user,
      theme,
      location,
      treeMode,
      onChangeViewMode,
      groupMode,
      basePathDepth,
      onBlockNavigation
    } = this.props;
    const {
      filterModel,
      loading,
      teamList,
      definitions,
      filterError,
      showExportDialog,
      sortOrder,
      basePath,
      group,
      groupId,
      editAttribute,
      successMessage,
      addEditAttributeDialogMode,
      deletingAttribute,
      deleteAttributeError
    } = this.state;

    const pathSplit = location.pathname.split("/");
    const newFilterModel = filterModel.clone();

    let selectedEmployee = null;
    let selectedEmployeeAttributeId = null;

    const activeTab = this.getActiveTab();
    const isInstructorViewing = teamList && teamList.isInstructor;
    const isNotInstructor = teamList && teamList.isInstructor === false;

    if (teamList && activeTab === "") {
      selectedEmployee = teamList.getEmployee(pathSplit[basePathDepth + 1]);
      selectedEmployeeAttributeId = pathSplit[basePathDepth + 3] || null;
    }

    const addOrEditCompetencies =
      location.hash === "#add" || location.hash?.includes("#edit") || location.hash === "#details";

    const isSelfView = selectedEmployee?.employeeId === user?.employeeId;

    return (
      <TeamProvider>
        <View theme={theme} scrollToTopOnMount={true}>
          <Stack direction='row' spacing={2} justifyContent='space-between'>
            <ViewHeading text={groupMode ? (group ? group.name : null) : "My team"} />

            <div>
              {user && ((!groupMode && user.isTeamLead) || (groupMode && user.hasGroup)) && (
                <Button
                  className='klaro_team-filterbutton'
                  label='Filters'
                  theme={theme}
                  size='md'
                  variant='filled'
                  startIcon={<FlterIcon />}
                  showLabelFromBreakpoint='md'
                  onClick={this.openFilterPanel.bind(this)}
                />
              )}

              <TeamFilterPanel
                ref={this.filterPanelRef}
                theme={theme}
                groupId={groupId}
                definitions={definitions}
                filterModel={newFilterModel}
                fullScreenToBreakpoint='md'
                anchor={this.filterButton}
                includeExpiry={true}
                onApply={this.onFilterPanelApply.bind(this)}
                onClose={this.onFilterPanelClose.bind(this)}
              />
            </div>
          </Stack>

          {filterError && (
            <Alert severity='error' sx={{ marginBottom: "20px" }}>
              {filterError}
            </Alert>
          )}

          {filterModel && filterModel.countFilters() > 0 && (
            <TeamFilterChips
              filterModel={filterModel}
              onChange={this.onFilterChipsChange.bind(this)}
              theme={theme}
              showSingleToBreakpoint='md'
              sx={{ marginBottom: "26px" }}
            />
          )}

          <PillTabs
            user={user}
            organization={organization}
            theme={theme}
            path={basePath + (activeTab !== "" ? "/" + activeTab : "")}
            basePath={basePath}
            pages={this.getActivePages(organization)}
            params={newFilterModel.toUrlParams()}
            sx={{ marginBottom: "41px" }}
          />

          {user && ((!groupMode && user.isTeamLead) || (groupMode && user.hasGroup)) && (
            <>
              <Switch>
                <Route
                  exact={false}
                  path={[basePath + "/regulations", basePath + "/regulations/:id"]}
                >
                  <TeamRegulationSection
                    heading={(groupMode ? "Group" : "Team") + " regulations"}
                    organization={organization}
                    theme={theme}
                    filter={this.state.filterModel}
                    groupId={this.state.groupId}
                    onEmployeeSelect={(e: any, employee: any) => {
                      this.onEmployeeSelect(e, employee);
                    }}
                    basePath={basePath}
                    basePathDepth={basePathDepth}
                    sortOrder={sortOrder}
                    onSortOrderChange={this.onSortOrderChange.bind(this)}
                  />
                </Route>
                <Route exact={false} path={[basePath + "/tasks", basePath + "/tasks/:id"]}>
                  <TeamTasksSection
                    heading={(groupMode ? "Group" : "Team") + " tasks"}
                    organization={organization}
                    theme={theme}
                    filter={this.state.filterModel}
                    groupId={this.state.groupId}
                    onEmployeeSelect={(e: any, employee: any) => {
                      this.onEmployeeSelect(e, employee);
                    }}
                    basePath={basePath}
                    basePathDepth={basePathDepth}
                    sortOrder={sortOrder}
                    onSortOrderChange={this.onSortOrderChange.bind(this)}
                  />
                </Route>
                <Route exact={false} path={[basePath + "/trainings", basePath + "/trainings/:id"]}>
                  <TeamTrainingSection
                    heading={(groupMode ? "Group" : "Team") + " training"}
                    organization={organization}
                    theme={theme}
                    filter={this.state.filterModel}
                    groupId={this.state.groupId}
                    onEmployeeSelect={(e: any, employee: any) => {
                      this.onEmployeeSelect(e, employee);
                    }}
                    basePath={basePath}
                    basePathDepth={basePathDepth}
                    sortOrder={sortOrder}
                    onSortOrderChange={this.onSortOrderChange.bind(this)}
                  />
                </Route>
                <Route
                  exact={false}
                  path={[basePath + "/competencies", basePath + "/competencies/:id"]}
                >
                  <TeamAttributesSection
                    heading={(groupMode ? "Group" : "Team") + " competencies"}
                    organization={organization}
                    theme={theme}
                    loading={loading}
                    attributes={teamList ? teamList.attributes : null}
                    allowExport={false}
                    sortOrder={sortOrder}
                    onSortOrderChange={this.onSortOrderChange.bind(this)}
                    onShowExportDialog={this.onShowExportDialog.bind(this)}
                    onEmployeeSelect={(e: any, employee: any) => {
                      this.onEmployeeSelect(e, employee);
                    }}
                    filter={this.state.filterModel}
                    basePath={basePath}
                    basePathDepth={basePathDepth}
                  />
                </Route>
                <Route exact={false} path={[basePath + "/:id"]}>
                  <EmployeeProfileDialog
                    ref={this.setEmployeeRef}
                    user={user}
                    location={location}
                    organization={organization}
                    theme={theme}
                    treeMode={treeMode}
                    onChangeViewMode={onChangeViewMode}
                    employee={selectedEmployee}
                    employeeAttribute={editAttribute}
                    attribute={selectedEmployeeAttributeId || editAttribute?.employeeAttributeId}
                    isInstructorViewing={isInstructorViewing}
                    isNotInstructor={isNotInstructor}
                    onClose={this.onCloseEmployeeProfileDialog.bind(this)}
                    onShowAttributeDetails={
                      isSelfView
                        ? this.onShowAttributeDetails.bind(this)
                        : this.onEditEmployeeAttribute.bind(this)
                    }
                    onCloseAttributeDialog={this.onCloseEmployeeAttributeDetails.bind(this)}
                    basePath={
                      selectedEmployee ? basePath + "/" + selectedEmployee.employeeId : basePath
                    }
                    onEditAttribute={this.onEditEmployeeAttribute.bind(this)}
                    isTeamView={true}
                    isSelfView={isSelfView}
                    addOrEditCompetencies={addOrEditCompetencies}
                    addEditAttributeDialogMode={addEditAttributeDialogMode}
                    onAddAttributeClickCb={this.onAddAttributeClickCb.bind(this)}
                    onAddChangeAction={this.onAddChangeAction.bind(this)}
                    onDeleteAttribute={this.onDeleteAttribute.bind(this)}
                    onFeedbackAction={this.onFeedbackAction.bind(this)}
                    onProficiencyChangeAction={this.onProficiencyChangeAction.bind(this)}
                  />
                </Route>
                <Route exact={false} path={[basePath]}>
                  <TeamPeopleSection
                    key={groupId}
                    heading={(groupMode ? "Group" : "Team") + " members"}
                    organization={organization}
                    user={user}
                    theme={theme}
                    loading={loading}
                    teamList={teamList}
                    onEmployeeSelect={this.onEmployeeSelect.bind(this)}
                    onViewAllClassifications={this.onViewAllClassifications.bind(this)}
                    onExport={this.onExport.bind(this)}
                    allowExport={true}
                    sortOrder={sortOrder}
                    onSortOrderChange={this.onSortOrderChange.bind(this)}
                    onShowExportDialog={this.onShowExportDialog.bind(this)}
                  />
                </Route>
              </Switch>
            </>
          )}

          {user && !user.isTeamLead && !groupMode && (
            <Placeholder
              text={{
                primary: "Nothing to show yet",
                secondary: (
                  <>
                    This section requires having employees report to you.
                    <br />
                    Go to Settings and set one or more employees to report to you.
                  </>
                )
              }}
              icon={<UsersIcon />}
              sx={{ height: "100vh", maxHeight: "60vh" }}
            />
          )}

          {selectedEmployee && addOrEditCompetencies && !isSelfView && isInstructorViewing ? (
            <InstructorAddEditAttributeDialog
              theme={theme}
              user={user}
              orgHasProficiency={organization && organization.showProficiency}
              employee={selectedEmployee}
              location={location}
              onClose={this.onCloseAddEditAttributeDialog.bind(this)}
              attribute={editAttribute}
              onBlockNavigation={onBlockNavigation}
            />
          ) : null}

          {deletingAttribute && (
            <DeleteConfirmationDialog
              theme={theme}
              error={deleteAttributeError}
              onClose={this.closeDeleteDialog.bind(this)}
              onAccept={this.deleteAttribute.bind(this)}
            />
          )}
          {successMessage && (
            <Snackbar
              open={true}
              duration={3000}
              type='success'
              onClose={this.onSnackBarClose.bind(this)}
              message={successMessage}
            />
          )}

          {teamList && (
            <JobClassiciationsDialog
              theme={theme}
              ref={this.setDefinitiomDialogRef}
              classifications={teamList.classificationsSummary}
              onClose={this.onCloseClassificationsDialog.bind(this)}
            />
          )}

          {showExportDialog && (
            <ConfirmationDialog
              title='Export'
              theme={theme}
              question='An Excel document will be generated and sent to your Klayo registered email address once it’s ready.'
              cancelButton='Cancel'
              acceptButton='Export'
              onCancel={this.onCancelExportDialog.bind(this)}
              onAccept={this.onExport.bind(this)}
            />
          )}
        </View>
      </TeamProvider>
    );
  }
}
