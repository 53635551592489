// import { CheckCircle, XCircle } from "lucide-react";
import { Box, LinearProgress, Skeleton, Stack, Typography } from "@mui/material";
import { ReactComponent as TickIconGreen } from "../../resources/images/icons-check-green.svg";
import { ReactComponent as RemoveIconRed } from "../../resources/images/icons-remove-red.svg";
import React from "react";
import { Table } from "./Table";
import { Utils } from "../../common/Utils";
import { Tooltip } from "./../Tooltip";

export class JobMatchingTable extends Table {
  static columns = {
    summary: {
      field: "summary",
      label: "Summary",
      visibleToBreakpoint: "md"
    },
    job: {
      field: "job",
      label: "JOB",
      visibleFromBreakpoint: "md"
    },
    minNeeded: {
      field: "minNeededTotal",
      label: "Min competencies",
      visibleFromBreakpoint: "md"
    },
    allCompetencies: {
      field: "competenciesTotal",
      label: "All competencies",
      visibleFromBreakpoint: "md"
    },
    overallMatching: {
      field: "matchingPercentage",
      label: "Overall match",
      visibleFromBreakpoint: "md"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: JobMatchingTable.columns.job },
      { type: JobMatchingTable.columns.minNeeded },
      { type: JobMatchingTable.columns.allCompetencies },
      { type: JobMatchingTable.columns.overallMatching }
    ],
    noRowsMessage: "No jobs available",
    rowHasAction: null,
    selectable: false,
    sortable: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: [
        {
          field: "overallMatching",
          sort: "desc"
        }
      ]
    };
  }

  getRowId(e: any) {
    return this.tableId + "_" + e.id;
  }
  onRowClick(params: any, e: any) {
    const { onJobSelect } = this.props;
    onJobSelect(params.row);
  }
  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === JobMatchingTable.columns.summary)
          this.columns.push({
            field: "summary",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            filterable: false,
            flex: 1,
            disableReorder: true,
            renderCell: (params: any) => (
              <div
                className={
                  "klayo-table_multilinerow" +
                  (this.props.tree && params.row.isChild === true ? " klayo-table_childattr" : "") +
                  (params.row.isParent && params.rowNode.childrenExpanded
                    ? " klayo-table_rowexpanded"
                    : "")
                }
              >
                <div className='klayo-attrtable_multilinerow_row1'>{params.row.job}</div>
                <div className='klayo-attrtable_multilinerow_row2'>
                  <Box display='flex' alignItems='center' gap={1}>
                    <LinearProgress
                      variant='determinate'
                      value={params.row.matchingPercentage}
                      sx={{
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#E5E7EB", // Light gray background
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#6366F1", // Blue color
                          borderRadius: 4
                        },
                        width: "100%"
                      }}
                    />
                    <Typography variant='body2' color='textSecondary'>
                      {params.row.matchingPercentage}%
                    </Typography>
                  </Box>
                </div>
              </div>
            )
          });
        if (c.type === JobMatchingTable.columns.job)
          this.columns.push({
            field: "job",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 5,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            valueGetter: (params: any) => params.row.job,
            getRowId: (params: any) => this.getRowId(params.row)
          });

        if (c.type === JobMatchingTable.columns.minNeeded)
          this.columns.push({
            field: "minNeededTotal",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 2,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            renderCell: (params: any) => (
              <div
                className='flex items-center gap-2'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px"
                }}
              >
                <span>
                  {params.row.minNeeded} of {params.row.minNeededTotal}
                </span>
                {params.row.minNeeded >= params.row.minNeededTotal ? (
                  <TickIconGreen />
                ) : (
                  <RemoveIconRed />
                )}
              </div>
            )
          });

        if (c.type === JobMatchingTable.columns.allCompetencies)
          this.columns.push({
            field: "competenciesTotal",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 2,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            renderCell: (params: any) => (
              <span>
                {params.row.competenciesMet} of {params.row.competenciesTotal}
              </span>
            )
          });

        if (c.type === JobMatchingTable.columns.overallMatching)
          this.columns.push({
            field: "matchingPercentage",
            headerName: c.label || c.type.label,
            sortable: sortable,
            flex: 2,
            filterable: false,
            disableReorder: true,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            renderCell: (params: any) => (
              <Box display='flex' alignItems='center' gap={1} flex={1}>
                <Tooltip title={`${params.row.matchingPercentage}%`} arrow>
                  <LinearProgress
                    variant='determinate'
                    value={params.row.matchingPercentage}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "#E5E7EB", // Light gray background
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#6366F1", // Blue color
                        borderRadius: 4
                      },
                      flex: 1
                    }}
                  />
                </Tooltip>
                <Typography variant='body2' color='textSecondary'>
                  {params.row.matchingPercentage}%
                </Typography>
              </Box>
            )
          });
      }
    });
  }
}
