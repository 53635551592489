import { AppBar, Box, Dialog, DialogContent, Grid, Stack, Toolbar } from "@mui/material";
import { ConfirmationDialog, JobDescriptionContainer, Switch } from "klayowebshared";

import { JobMatchingProgress } from "../../components/JobMatchingProgress";
import { Paper } from "../../components/Paper";

import { SectionLoader } from "../../components/SectionLoader";

import { useHistory } from "react-router";
import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from "../../resources/images/icons-close.svg";
import { ReactComponent as PrintIcon } from "../../resources/images/icons-print.svg";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";
import { JobDescriptionDialog } from "./JobDescriptionDialog";

import { useMemo } from "react";
import { JobCompetenciesTable } from "../../components/table/JobMatchingDetailTable";
import { useJobMatchingDetails } from "../../hooks/useJobMatchingDetails";

const ProfileJobMatchingDetails = (props: any) => {
  const { employee, theme, location } = props;
  const history = useHistory();

  const jobId = useMemo(() => {
    if (!location) return;
    const path = location.pathname.split("/");
    return path[path.length - 1];
  }, [location]);

  const handleCloseModalDetail = () => {
    history.goBack();
  };

  const {
    jobMatchingDetail,
    jobAttributes,
    isLoading,
    showFullDescription,
    showGapsOnly,
    showPrintModal,
    handleOpenPrintModal,
    handleClosePrintModal,
    handleOpenJobDescriptionDialog,
    handleCloseJobDescriptionDialog,
    handleSwitchShowGaps,
    handleConfirmPrint
  } = useJobMatchingDetails({ jobId, employee });

  return (
    <Dialog
      open={!!jobId}
      className='klaro_employeeprofile'
      fullScreen={true}
      maxWidth='xs'
      hideBackdrop
      transitionDuration={0}
    >
      <AppBar className='klaro_employeeprofile-appbar' elevation={0}>
        <Toolbar>
          <Logo className='klaro-appbar_logo' />
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box>
            <Button
              autoFocus
              label='Close'
              variant='outlined'
              size='md'
              startIcon={<CloseIcon />}
              onClick={handleCloseModalDetail}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          marginTop: { xs: "56px", md: "64px" },
          padding: { xs: "40px 16px", md: "40px 32px" },
          backgroundColor: "#F8F8FE"
        }}
      >
        <Box
          sx={{
            marginLeft: { xs: "0", md: "64px" },
            marginRight: { xs: "0", md: "64px" }
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2
              }}
            >
              <Stack
                direction='column'
                justifyContent='flex-start'
                alignItems={{ xs: "flex-start", md: "flex-start" }}
                spacing={0}
                sx={{ flex: 1, minWidth: 0 }}
              >
                <Box
                  className='klayo-userprofilebox_name'
                  sx={{
                    fontSize: { xs: "20px", md: "24px" },
                    height: { xs: "1.4", md: "1.33" },
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: { md: "calc(100% - 24px)" }
                  }}
                >
                  <span>{jobMatchingDetail?.jobName}</span>
                </Box>

                <Box
                  className='klayo-userprofilebox_title'
                  sx={{
                    fontSize: { xs: "16px", md: "16px" }
                  }}
                >
                  Job match detail for {employee.fullName}
                </Box>
              </Stack>
              <Button
                className='print-button'
                label='Print'
                variant='outlined'
                startIcon={<PrintIcon />}
                size='md'
                onClick={handleOpenPrintModal}
                disabled={isLoading}
                sx={{ display: { xs: "none", sm: "flex" } }}
              />
              {showPrintModal && (
                <ConfirmationDialog
                  title='Print'
                  theme={theme}
                  question='Send a PDF to your registered email, showing this job match?'
                  cancelButton='Cancel'
                  acceptButton='Confirm'
                  onCancel={handleClosePrintModal}
                  onAccept={handleConfirmPrint}
                  loading={isLoading}
                />
              )}
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={6}>
                <Paper
                  className='klaro_section klaro_profile-jobdesc'
                  title={"Job description"}
                  sx={{ height: "100%", boxSizing: "border-box" }}
                >
                  {isLoading ? (
                    <SectionLoader />
                  ) : (
                    <JobDescriptionContainer
                      text={jobMatchingDetail?.description || ""}
                      maxHeight={180}
                      onShowMore={handleOpenJobDescriptionDialog}
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Paper
                  className='klaro_section klaro_profile-attroverview'
                  title={"Job competencies overview"}
                  sx={{ height: "100%", boxSizing: "border-box" }}
                >
                  {isLoading ? (
                    <SectionLoader />
                  ) : (
                    <JobMatchingProgress
                      mandatoryMet={jobMatchingDetail?.numberOfMatchingMinimumJobCompetencies}
                      mandatoryTotal={jobMatchingDetail?.numberOfMinimumJobCompetencies}
                      requiredMet={jobMatchingDetail?.numberOfMatchingJobCompetencies}
                      requiredTotal={jobMatchingDetail?.numberOfJobCompetencies}
                      progressBarProps={{
                        showFraction: true,
                        showPercent: true
                      }}
                      // spacing='20px'
                    />
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  className='showgap-button'
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Switch
                    label='Show gaps only'
                    checked={showGapsOnly}
                    onChange={handleSwitchShowGaps}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <JobCompetenciesTable
                  title={"Job competencies"}
                  theme={theme}
                  minHeight='300px'
                  dense={true}
                  rows={jobAttributes || []}
                  showHeaderFromBreakpoint='sm'
                  columns={[
                    {
                      type: JobCompetenciesTable.columns.summary
                    },
                    { type: JobCompetenciesTable.columns.competency, visibleFromBreakpoint: "sm" },
                    { type: JobCompetenciesTable.columns.category, visibleFromBreakpoint: "sm" },
                    { type: JobCompetenciesTable.columns.priority, visibleFromBreakpoint: "sm" },
                    { type: JobCompetenciesTable.columns.status, visibleFromBreakpoint: "sm" }
                  ]}
                  rowHasAction={() => true}
                  sx={{ padding: "23px" }}
                  tableSx={{ margin: "0 -7px" }}
                  selectable={true}
                />
              </Grid>
            </Grid>
          </>

          {showFullDescription && (
            <JobDescriptionDialog
              theme={theme}
              description={jobMatchingDetail?.description}
              onClose={handleCloseJobDescriptionDialog}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileJobMatchingDetails;
