import { Component } from "react";
import { AttributeProgressBar } from "./AttributeProgressBar";
import { AttributeProgressBarLabel } from "./AttributeProgressBarLabel";

export class JobMatchingProgress extends Component<any, any> {
  static defaultProps = {
    progressBarProps: {
      showFraction: false,
      showPercent: true
    },
    mandatoryBarColor: "#3dbd35",
    requiredBarColor: "#2196f3",
    desirableBarColor: "#f9b800",
    spacing: "32px"
  };

  render() {
    const {
      title,
      onMandatoryClick,
      onRequiredClick,

      singleRowProgressBar,

      mandatoryBarColor,
      requiredBarColor,

      spacing,
      loading
    } = this.props;
    const { mandatoryMet, mandatoryTotal, requiredMet, requiredTotal } = this.props;

    return (
      <div>
        <div className='klaro_attr-progressoverrview'>
          {singleRowProgressBar && (
            <div className='klaro_attr-progressoverrview_labels'>
              <div>
                <AttributeProgressBarLabel
                  label='Minimum competencies'
                  met={mandatoryMet}
                  total={mandatoryTotal}
                  onClick={onMandatoryClick}
                  singleRow={true}
                  loading={loading}
                />
              </div>
              <div style={{ marginTop: spacing }}>
                <AttributeProgressBarLabel
                  label='All competencies'
                  met={requiredMet}
                  total={requiredTotal}
                  onClick={onRequiredClick}
                  singleRow={true}
                  loading={loading}
                />
              </div>
            </div>
          )}
          <div className='klaro_attr-progressoverrview_bars'>
            <div>
              <AttributeProgressBar
                color={mandatoryBarColor}
                label='Minimum competencies'
                met={mandatoryMet}
                total={mandatoryTotal}
                onClick={onMandatoryClick}
                singleRow={singleRowProgressBar}
                showLabel={!singleRowProgressBar}
                barHeight={12}
                progressBarProps
                loading={loading}
              />
            </div>
            <div style={{ marginTop: spacing }}>
              <AttributeProgressBar
                color={requiredBarColor}
                label='All competencies'
                met={requiredMet}
                total={requiredTotal}
                onClick={onRequiredClick}
                singleRow={singleRowProgressBar}
                showLabel={!singleRowProgressBar}
                barHeight={12}
                progressBarProps
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
