import { User } from "./User";

export class CurrentUser extends User {
  static accessLevel = {
    none: {
      level: -1
    },
    user: {
      level: 0
    },
    employee: {
      level: 1
    },
    mananger: {
      level: 2
    },
    organizationAdmin: {
      level: 3
    }
  };

  loggedInUser: any;
  activeUser: any;
  isCurrent!: boolean;
  isDeveloper!: boolean;
  hasGroup!: boolean;
  loggedInUserEmployeeId: any;
  loggedInUserFirstName!: string;
  loggedInUserLastName!: string;
  loggedInUserFullName!: string;
  loggedInUserHasJob!: boolean;
  loggedInUserJobTitle!: string;
  loggedInUserIsOrganizationAdmin!: boolean;
  loggedInUserIsBillingUser!: boolean;
  loggedInUserIsTeamLead!: boolean;
  canChangeUser!: boolean;
  canChangeOrg!: boolean;
  impersonating!: boolean;
  hasDemoEmployee!: boolean;
  isAdmin!: boolean;
  isBillingUser!: boolean;
  accessLevel!: any;
  hasAcceptedTerms!: boolean;
  isParticipant!: boolean;
  accessToken!: any;
  static instance: CurrentUser;

  constructor(options: any) {
    super({
      api: "/User",
      callback: (data: any) => {
        options.resolve(this);
        this.email =
          options.context.msal &&
          options.context.msal.accounts &&
          options.context.msal.accounts.lenth !== 0
            ? options.context.msal.accounts[0].username
            : null;
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.initData(data);
  }

  initData(data: any) {
    this.loggedInUser = data.loggedInUser;
    this.activeUser = data.activeUser;

    super.initData(this.activeUser.basicInformation);

    this.isCurrent = true;
    this.isDeveloper = this.loggedInUser.permissions.isDeveloper;
    this.hasGroup = this.activeUser.basicInformation.hasGroup;

    if (this.loggedInUser) {
      this.loggedInUserEmployeeId = this.loggedInUser.basicInformation.employeeId;
      this.loggedInUserFirstName = this.loggedInUser.basicInformation.firstName;
      this.loggedInUserLastName = this.loggedInUser.basicInformation.lastName;
      this.loggedInUserFullName = this.loggedInUser.basicInformation.fullName;
      this.loggedInUserHasJob = this.loggedInUser.basicInformation.hasJob;
      this.loggedInUserJobTitle = this.loggedInUser.basicInformation.jobTitle;
      this.loggedInUserIsOrganizationAdmin = this.loggedInUser.basicInformation.isOrganizationAdmin;
      this.loggedInUserIsBillingUser = this.loggedInUser.basicInformation.isBillingUser;
      this.loggedInUserIsTeamLead = this.loggedInUser.basicInformation.isTeamLead;

      this.canChangeUser = this.loggedInUser.permissions.canChangeUser;
      this.canChangeOrg = this.loggedInUser.permissions.canChangeOrg;

      this.impersonating =
        this.loggedInUser.basicInformation.employeeId !==
        this.activeUser.basicInformation.employeeId;
      this.hasDemoEmployee = this.loggedInUser.permissions.hasDemoEmployeeId;
    }
    this.isOrganizationAdmin = this.activeUser.basicInformation.isOrganizationAdmin;
    this.isTeamLead = this.activeUser.basicInformation.isTeamLead;
    this.isAdmin = this.isOrganizationAdmin || this.isTeamLead;
    this.isBillingUser = this.activeUser.basicInformation.isBillingUser;
    this.hasJob = this.activeUser.basicInformation.hasJob;
    this.status = Object.values(User.status)[this.activeUser.basicInformation.status] || null;
    this.accessLevel = this.getAccessLevel();
    this.hasAcceptedTerms = this.activeUser.basicInformation.organizationAcceptTermsAndConditions;
    this.isInstructor = this.activeUser.basicInformation.isInstructor;
    this.isParticipant = this.activeUser.basicInformation.isParticipant;
  }

  getAccessLevel() {
    if (!this.isActive()) return CurrentUser.accessLevel.none;
    if (this.isOrganizationAdmin) return CurrentUser.accessLevel.organizationAdmin;
    if (this.isTeamLead) return CurrentUser.accessLevel.mananger;
    if (this.hasJob) return CurrentUser.accessLevel.employee;
    return CurrentUser.accessLevel.user;
  }

  onGetToken(accessToken: any) {
    this.accessToken = accessToken;
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (CurrentUser.instance && updateCache !== true)
        resolve({ data: CurrentUser.instance, cached: true });
      else CurrentUser.instance = new CurrentUser({ resolve, reject, context });
    });
  }

  hasAttribute(attribute: any) {
    if (!this.profile) return false;
    return this.profile.hasAttribute(attribute);
  }

  hasAccess(level: any) {
    return level.level === undefined || this.accessLevel.level >= level.level;
  }
}
