import { Grid, Stack } from "@mui/material";
import React, { Component } from "react";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { Organization } from "../../data/Organization";
import { ReactComponent as Drawing } from "../../resources/images/drawing-inactive.svg";

export class InactiveView extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = this.generateMessage(props.user, props.organization);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.user !== this.props.user || prevProps.organization !== this.props.organization)
      this.generateMessage(this.props.user, this.props.organization);
  }

  generateMessage(user: any, organization: any) {
    if (organization && organization.status === Organization.status.inactive) {
      if (user && user.isBillingUser) {
        if (organization.isTrial)
          return {
            heading: "Your trial has expired",
            message:
              "Please purchase a plan to continue your journey with Klayo or contact us for a chat.",
            actions: ["contactUs", "buyNow"]
          };
        else
          return {
            heading: "Your organization is disabled",
            message: "Please contact us to discuss your needs.",
            actions: ["contactUs"]
          };
      } else {
        if (organization.isTrial)
          return {
            heading: "Your trial has expired",
            message: "Please contact your administrator to arrange access.",
            actions: null
          };
        else
          return {
            heading: "Your organization is disabled",
            message: "Please contact your administrator to arrange access.",
            actions: null
          };
      }
    }
    return {
      heading: "You do not have access",
      message:
        "Your access to this organization is currently disabled. Please contact your administrator to arrange access.",
      actions: null
    };
  }

  onContactClick(e: any) {
    const { onContactClick } = this.props;
    if (onContactClick) onContactClick(e);
  }

  onBuyClick(e: any) {
    const { onBuyClick } = this.props;
    if (onBuyClick) onBuyClick(e);
  }

  render() {
    const { heading, message, actions } = this.state;

    return (
      <Paper className='klayo-inactiveuserview' padding='44px 64px 48px 64px'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h1>{heading}</h1>
            <div className='klayo-inactiveuserview_message'>{message}</div>
            <Stack direction='row' className='klayo-inactiveuserview_actions' spacing={2}>
              {actions && actions.includes("contactUs") && (
                <Button label='Contact us' onClick={this.onContactClick.bind(this)} />
              )}

              {actions && actions.includes("buyNow") && (
                <Button variant='filled' label='Buy now' onClick={this.onBuyClick.bind(this)} />
              )}
            </Stack>
          </Grid>
          <Grid item xs={6} className='klayo-inactiveuserview_drawing'>
            <Drawing />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
