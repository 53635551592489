import { Stack } from "@mui/material";
import { debounce, find, get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "../dialogs/Dialog";
import { MultiAttributeSelector } from "./MultiAttributeSelector";

export const JobMatchingCompetencySelector = (props: any) => {
  const {
    onClose,
    onShowNewAttributeDialog,
    excludedAttributes,
    onSave,
    theme,
    initialSelectedAttributes = [],
    attributes,
    onSearch,
    isLoading,
    open
  } = props;

  const [selectedAttributes, setSelectedAttributes] = useState<any[]>([]);

  const handleAttributesChange = (e: any, selected: any) => {
    setSelectedAttributes(selected);
  };

  const handleSave = () => {
    if (onSave) onSave(selectedAttributes);
  };

  const attributesFiltered = useMemo(() => {
    return attributes;
  }, [attributes]);

  useEffect(() => {
    setSelectedAttributes(initialSelectedAttributes);
  }, [initialSelectedAttributes]);

  return (
    <Dialog
      open={open}
      theme={theme}
      onClose={onClose}
      fullWidth={true}
      maxWidth='sm'
      title='Filters'
      actions={[
        {
          label: "Apply",
          primary: true,
          disabled: selectedAttributes.length === 0,
          onClick: handleSave
        },
        {
          label: "Cancel",
          onClick: onClose
        }
      ]}
    >
      <Stack direction='column' spacing={2}>
        <div>Competency</div>

        <MultiAttributeSelector
          label={null}
          items={attributesFiltered}
          values={selectedAttributes}
          onNewItemClick={onShowNewAttributeDialog}
          getItemId={(attr: any) => attr.attributeId}
          onChange={handleAttributesChange}
          hasNewItem={false}
          isLoading={isLoading}
        />
      </Stack>
    </Dialog>
  );
};
