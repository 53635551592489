import { Box, CircularProgress, Stack } from "@mui/material";
import { OrgChart } from "klayowebshared";
import React from "react";
import { AppContext } from "../../common/AppContext";
import { AppDrawer } from "../../components/AppDrawer";
import { TextField } from "../../components/TextField";
import { ViewHeading } from "../../components/typography/ViewHeading";
import { View } from "../../components/View";
import { OrgUserList } from "../../data/users/OrgUserList";
import { AppPages } from "../../router";
import { AbstractView } from "../AbstractPage";
import { EmployeeProfileDialog } from "../profile/EmployeeProfileDialog";

export class CompanyView extends AbstractView {
  static contextType = AppContext;
  chartRef: any;
  prevPath: string | null = null;

  constructor(props: any) {
    super(props, AppPages.company);

    this.state = {
      data: null,
      height: this.getChartHeight(),
      centerOn: props.user ? props.user.employeeId : null,
      search: null
    };

    this.chartRef = React.createRef();
    window.addEventListener("resize", this.onWindowResize.bind(this));
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadData();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.organization !== prevProps.organization) this.forceUpdate();
    if (this.props.showBillingBanner !== prevProps.showBillingBanner) this.forceUpdate();
  }

  onWindowResize(e: any) {
    this.setState({ height: this.getChartHeight() });
  }

  getChartHeight() {
    const { theme, showBillingBanner } = this.props;
    const breakpoint = theme.getCurrentBreakpoint()[0];
    return window.innerHeight - (View.topPadding as any)[breakpoint] - (showBillingBanner ? 44 : 0);
  }

  onHasUser(user: any) {
    this.centerOnCurrentUser();

    this.setState({ centerOn: user.employeeId });
  }

  centerOnCurrentUser() {
    //const { user } = this.props;
    //if(user && this.chartRef) this.chartRef.current.centerOn(user.employeeId);
  }

  loadData() {
    const { organization, dataVersion } = this.props;

    this.context.setLoading("employees", true);

    OrgUserList.get(this.context, dataVersion)
      .then((r: any) => {
        this.setState({ data: r.data }, () => {
          this.centerOnCurrentUser();
        });
      })
      .catch((error: any) => {})
      .finally(() => this.context.setLoading("employees", false));
  }

  getEmployeeIdFromPath() {
    const pathSplit = this.props.location.pathname.split("/");
    return pathSplit.length >= 3 ? pathSplit[2] : null;
  }

  onViewProfile(e: any, employee: any) {
    this.prevPath = this.props.location.pathname;
    this.setState({ centerOn: null });
    this.setLocation("/company/" + employee.employeeId);
  }

  onCloseEmployeeProfileDialog(e: any) {
    this.setLocation(this.prevPath ? this.prevPath : "/company");
  }

  onShowEmployeeAttributeDetails(e: any, attr: any) {
    this.setLocation(window.location.pathname + `#details=${attr.employeeAttributeId}`);
  }

  onCloseEmployeeAttributeDetails(e: any, attr: any) {
    this.setLocation(location.pathname);
  }

  setLocation(path: any, useNative?: any) {
    if (useNative === true) window.history.pushState(null, "", path);
    else this.props.history.push(path);
  }

  getActiveTab() {
    const pathSplit = this.props.location.pathname.split("/");
    return pathSplit.length > 2
      ? pathSplit[2] === "gaps" || pathSplit[2] === "competencies"
        ? pathSplit[2]
        : ""
      : "";
  }

  onSearch(e: any) {
    const searchValue = e.target.value;

    this.setState({ search: searchValue }, () => {
      if (this.chartRef.current) {
        if (!searchValue) {
          // Clear all highlights when search is empty
          const { data } = this.state;
          if (data) {
            data.orgTree.forEach((emp: any) => {
              if (emp.highlight) emp.highlight = false;
            });
          }
          return;
        }

        if (this.state.data) {
          const matchedEmployee = this.state.data.orgTree.find(
            (employee: any) =>
              employee.type === "person" &&
              employee.fullName?.toUpperCase().includes(searchValue.toUpperCase())
          );
          if (matchedEmployee) {
            // new focusOnEmployee method
            this.chartRef.current.focusOnEmployee(matchedEmployee.employeeId);
            this.chartRef.current.setHighlight(matchedEmployee.employeeId, true);
          }
        }
      }
    });
  }

  render() {
    if (!this.verifyAccess()) return this.renderAccessDenied();

    const { user, organization, theme, location, treeMode, onChangeViewMode, showBillingBanner } =
      this.props;
    const { data, height, centerOn, search } = this.state;

    let selectedEmployee = null;
    let selectedEmployeeAttributeId = null;

    const activeTab = this.getActiveTab();

    const pathSplit = location.pathname.split("/");

    if (data && activeTab === "") {
      selectedEmployee = data.getEmployee(pathSplit[2]);

      if (location.hash) {
        const params = new URLSearchParams(location.hash.replace("#", "?"));
        selectedEmployeeAttributeId = params.get("details");
      }
    }

    return (
      <View
        theme={theme}
        nopadding={true}
        fullWidth={true}
        scrollToTopOnMount={true}
        fullHeight={true}
        showBillingBanner={showBillingBanner}
      >
        <Box
          sx={{
            position: "absolute",
            right: "0",
            left: { lg: AppDrawer.defaultProps.drawerWidth, xs: "0" },
            background: "linear-gradient(0deg, #f8f8fe00,#f8f8feff, #f8f8feff)",
            overflow: "hidden"
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              margin: "0 auto",
              width: "100%",
              maxWidth: { md: "960px", lg: "1040px", xl: "1440px" },
              padding: { xs: "0 16px", sm: "0 32px", md: "0 32px", lg: "0 48px", xl: "0 48px" }
            }}
          >
            <ViewHeading text='My company' />
            <TextField
              label='Search'
              value={search}
              onChange={this.onSearch.bind(this)}
              autoComplete={false}
              sx={{ width: "250px", display: "none" }}
            />
          </Stack>
        </Box>
        {organization && (
          <OrgChart
            ref={this.chartRef}
            onViewProfile={this.onViewProfile.bind(this)}
            organization={organization}
            data={data}
            height={height}
            currentUser={user ? user.employeeId : null}
            search={search}
            searchFilter={(n: any, search: any) =>
              n.type === "person" &&
              ((n.fullName && n.fullName.toUpperCase().includes(search.toUpperCase())) ||
                (n.jobTitle && n.jobTitle.toUpperCase().includes(search.toUpperCase())))
            }
            centerOn={centerOn}
          />
        )}

        {(!data || !organization) && (
          <div style={{ width: "100%", textAlign: "center", paddingTop: "100px" }}>
            <CircularProgress />
          </div>
        )}

        <EmployeeProfileDialog
          location={location}
          user={user}
          organization={organization}
          theme={theme}
          treeMode={treeMode}
          onChangeViewMode={onChangeViewMode}
          employee={selectedEmployee}
          attribute={selectedEmployeeAttributeId}
          onClose={this.onCloseEmployeeProfileDialog.bind(this)}
          onShowAttributeDetails={this.onShowEmployeeAttributeDetails.bind(this)}
          onCloseAttributeDialog={this.onCloseEmployeeAttributeDetails.bind(this)}
          basePath={"/company/" + selectedEmployee?.employeeId}
        />
      </View>
    );
  }
}
