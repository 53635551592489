import styled from "@emotion/styled";
import { Fade, Stack } from "@mui/material";
import { EditTemplate, Switch } from "klayowebshared";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { Paper } from "../../components/Paper";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TextField } from "../../components/TextField";
import { TooltipIcon } from "../../components/TooltipIcon";
import { ExpiryNotificationSelector } from "../../components/selectors/ExpiryNotificationSelector";
import { MultiAttributeSelector } from "../../components/selectors/MultiAttributeSelector";
import { MultiCategorySelector } from "../../components/selectors/MultiCategorySelector";
import { MultiCourseSelector } from "../../components/selectors/MultiCourseSelector";
import {
  COURSE_RECURRENCE,
  PAGE_SIZE_FOR_ADD_EDIT,
  TableSearchDebounceTime
} from "../../constants";
import { Data } from "../../data/Data";
import { Organization } from "../../data/Organization";
import { SettingsCourseList } from "../../data/settings/SettingCourseList";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { SettingsAttributeCategoryList } from "../../data/settings/SettingsAttributeCategoryList";
import { SettingsAttributeList } from "../../data/settings/SettingsAttributeList";
import { KLAYO_COLORS } from "../../themes";

import React from "react";
import { ViewComponent } from "../../components/ViewComponent";

export class AddEditAttributeView extends ViewComponent {
  static contextType = AppContext;

  static subPages = {
    standard: { name: "Standard", slug: "", tooltip: "A normal competency" },
    parent: {
      name: "Parent",
      slug: "parent",
      tooltip: "A parent competency which depends on its children to become approved"
    }
  };

  existingAttribute: any;
  debounceTimer: any;

  constructor(props: any) {
    super(props);
    const newAttribute = props.editMode
      ? new SettingsAttribute(props.attribute)
      : new SettingsAttribute({
          ...props.attribute,
          name: "" // Clear the name for new entries
        });
    this.state = {
      ...(ViewComponent as any).state,
      // attribute: props.attribute,
      attribute: newAttribute,
      categories: null,
      attributes: null,
      trainingCourseSelected: null,
      saving: false,
      error: props.error,
      expiryEnabled: false,
      expiryEnabledAutoOn: false,
      trainingEnable: false,
      proficiencyEnabled: false,
      hasEdit: false,
      hasSaveAndEdit: false,
      valid: props.attribute ? this.isValid(props.attribute) : false,
      integrationId: null,
      courses: null,
      coursesSearch: null,
      categoriesSearch: null,
      attributesSearch: null,
      organizationDialogMode: null
    };

    this.existingAttribute = new SettingsAttribute(props.attribute);
    this.debounceTimer = null;
  }

  componentDidMount() {
    this.loadDetails();
    this.loadChildAttributes();
    this.loadCourses("");
    this.loadCategories();
    this.loadOrganization();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { attribute, hasSaveAndEdit } = this.state;

    // reset form when save & create an other
    if (hasSaveAndEdit && this.props.attribute !== prevProps.attribute) {
      this.setState({
        attribute: this.props.attribute,
        trainingCourseSelected: [],
        trainingEnable: false,
        hasSaveAndEdit: false
      });
    }

    // assure that trainingCourseSelected has data
    if (
      this.state.trainingCourseSelected &&
      this.state.trainingCourseSelected !== prevState.trainingCourseSelected
    )
      this.onCheckDateExpiredAutoOn();

    // check if has selected training course enable training switch
    if (this.state.trainingCourseSelected !== prevState.trainingCourseSelected)
      this.onCheckHasTrainingCourse();

    if (this.props.attribute !== prevProps.attribute) {
      // this.setState({
      //     attribute: this.props.attribute,
      //     valid: this.isValid(this.props.attribute),
      //     expiryEnabled: false,
      //     proficiencyEnabled: false,
      //     saving: false, error: this.props.error
      // });
    }
    if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
    if (this.props.location !== prevProps.location)
      this.setState({ valid: this.isValid(attribute) });
    if (
      this.state.trainingEnable !== prevState.trainingEnable ||
      this.state.trainingCourseSelected !== prevState.trainingCourseSelected
    )
      this.setState({ valid: this.isValid(attribute) });
    if (this.props.orgSettings !== prevProps.orgSettings) this.forceUpdate();
  }

  loadDetails() {
    const { attribute } = this.state;
    const attributeId = attribute ? attribute.attributeId : this.props.attributeId;
    let trainingCourseSelected = [];

    if (!attributeId) {
      this.setState({ trainingCourseSelected: [] });
      return;
    }

    this.context.setLoading("loading", true);

    (Data as any)
      .get(this.context, "/AttributeDefinition/" + attributeId, {
        withCredentials: true
      })
      .then((response: any) => {
        const newAttribute = new SettingsAttribute(response.data.attributeDefinition);
        this.existingAttribute = new SettingsAttribute(response.data.attributeDefinition);

        setTimeout(() => {
          trainingCourseSelected = response.data.attributeDefinition?.trainingCourses;

          this.setState({
            attribute: newAttribute,
            trainingCourseSelected,
            valid: this.isValid(attribute)
          });
        }, 0);
      })
      .catch((e: any) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("loading", false);
      });
  }

  loadOrganization() {
    this.context.setLoading("org", true);

    Organization.get(this.context)
      .then((org) => {
        this.setState({
          organizationDialogMode: org
        });
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("org", false);
      });
  }

  loadCourses(searchText: any) {
    this.context.setLoading("training", true);

    // get setting courses data then set to courses state
    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };

    SettingsCourseList.getWithPaging(this.context, pagingOptions)
      .then((courses) => {
        this.setState({ courses: courses, coursesSearch: searchText });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("training", false));
  }

  onLoadCourseBlur() {
    const { coursesSearch } = this.state;

    if (coursesSearch) {
      this.loadCourses("");
    }
  }

  loadCategories(searchText?: any) {
    this.context.setLoading("categories", true);

    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };

    SettingsAttributeCategoryList.getWithPaging(this.context, pagingOptions)
      .then((categories) => {
        this.setState({ categories, categoriesSearch: searchText });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("categories", false));
  }

  onSearchCourse(searchText: any) {
    clearTimeout(this.debounceTimer);

    this.debounceTimer = setTimeout(() => {
      this.loadCourses(searchText);
    }, TableSearchDebounceTime);
  }

  onSearchCategories(searchText: any) {
    this.loadCategories(searchText);
  }

  onCategoriesBlur() {
    const { categoriesSearch } = this.state;

    if (categoriesSearch) this.loadCategories();
  }

  loadChildAttributes(searchText?: any) {
    this.context.setLoading("attributes", true);

    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };

    // get setting attributes data then set to attributes state
    SettingsAttributeList.getWithPaging(this.context, pagingOptions)
      .then((attributes) => {
        this.setState({ attributes, attributesSearch: searchText });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("attributes", false));
  }

  onSearchChildAttributes(searchText: any) {
    this.loadChildAttributes(searchText);
  }

  onChildAttributeBlur() {
    const { attributesSearch } = this.state;

    if (attributesSearch) this.loadChildAttributes();
  }

  onCheckDateExpiredAutoOn() {
    const { trainingCourseSelected } = this.state;

    const isAllNoneRecurrence = trainingCourseSelected.every(
      (course: any) => course.recurrence === COURSE_RECURRENCE.NONE.value
    );
    this.setState({ expiryEnabledAutoOn: !isAllNoneRecurrence });

    if (!isAllNoneRecurrence) {
      const toggleOnData = {
        target: {
          checked: true
        }
      };

      this.onExpiryEnabled(toggleOnData);
    }
  }

  onCheckHasTrainingCourse() {
    const { trainingCourseSelected, organizationDialogMode } = this.state;
    const { organization } = this.props;
    const organizationData = organization ? organization : organizationDialogMode;

    if (
      trainingCourseSelected &&
      trainingCourseSelected.length > 0 &&
      organizationData.hasIntegration
    ) {
      this.setState({ trainingEnable: true });

      return;
    }
  }

  onTrainingEnable(e: any) {
    const { attribute } = this.state;

    // check if switch off clear trainingCourses
    if (e.target?.checked === false) {
      attribute.trainingCourses = [];
      this.setState({ trainingCourseSelected: [] });
    }

    this.setState({ trainingEnable: e.target?.checked });
  }

  onCourseAttributeChange(e: any, courseAttributes: any) {
    const { attribute } = this.state;

    attribute.trainingCourses = courseAttributes;

    this.setState({
      attribute,
      trainingCourseSelected: courseAttributes,
      valid: this.isValid(attribute)
    });
  }

  isValid(attribute: any) {
    // const { trainingEnable } = this.state;
    const trainingEnable = this.state?.trainingEnable;

    if (trainingEnable) {
      return (
        this.isNameValid(attribute) &&
        (!this.isParent(attribute) ||
          (this.isParent(attribute) && this.isChildAttributesValid(attribute))) &&
        this.isTrainingCourseValid(attribute.trainingCourses)
      );
    }

    return (
      this.isNameValid(attribute) &&
      (!this.isParent(attribute) ||
        (this.isParent(attribute) && this.isChildAttributesValid(attribute)))
    );
  }

  isNameValid(attribute: any) {
    return attribute && attribute.name != null && attribute.name.length > 2;
  }

  isChildAttributesValid(attribute: any) {
    return (attribute && attribute.childAttributes.length > 0) === true;
  }

  isTrainingCourseValid(selectedCourse: any) {
    return selectedCourse && selectedCourse.length > 0;
  }

  onAttributeTypeChange(e: any) {
    const { trainingEnable } = this.state;

    // toggle off training when create parent attribute
    if (e.target.textContent === AddEditAttributeView.subPages.parent.name && trainingEnable) {
      this.setState({
        trainingEnable: false,
        trainingCourseSelected: []
      });
    }
  }

  onNameChange(e: any) {
    const { attribute } = this.state;

    attribute.name = e.target.value;

    this.setState({ attribute, valid: this.isValid(attribute) });
  }

  onImportCodeChange(e: any) {
    const { attribute } = this.state;
    attribute.importCode = e.target.value;

    this.setState({ attribute });
  }

  onDescriptionChange(e: any) {
    const { attribute } = this.state;
    attribute.description = e.target.value;

    this.setState({ attribute });
  }

  onCategoriesChange(e: any, categories: any) {
    const { attribute } = this.state;
    attribute.categories = categories;

    this.setState({ attribute });
  }

  onChildAttributesChange(e: any, childAttributes: any) {
    const { attribute } = this.state;
    attribute.childAttributes = childAttributes;

    this.setState({ attribute, valid: this.isValid(attribute) });
  }

  onSave(returnToList: any) {
    const { onSave, onBlockNavigation } = this.props;
    const { attribute, trainingEnable } = this.state;
    let hasSaveAndEdit = false;

    if (!returnToList) hasSaveAndEdit = true;

    // parent attribute not allowed trainingCourse
    if (this.isParent(attribute)) {
      attribute.trainingCourses = [];
      this.setState({ trainingCourseSelected: [] });
    }

    if (onBlockNavigation) onBlockNavigation(false, "Attribute");
    this.setState({ saving: true, hasSaveAndEdit });
    if (onSave) onSave(attribute, returnToList);
  }

  onSaveAndCreate() {
    this.onSave(false);
  }

  onExpiryEnabled(e: any) {
    const { onOpenDialog } = this.props;
    const { attribute, expiryEnabledAutoOn } = this.state;
    //prevent close when has recurrence not none
    if (expiryEnabledAutoOn && !e.target.checked) {
      onOpenDialog();
      return;
    }

    attribute.hasExpiryDate = e.target.checked;
    // this code cause empty array all the 1st time rendering
    // attribute.expiryNotifications = [];

    this.setState({ attribute });
  }

  onProficiencyEnabled(e: any) {
    const { attribute } = this.state;
    attribute.hasProficiency = e.target.checked;

    this.setState({ attribute });
  }

  onExpiryNotificationChange(expiryNotifications: any) {
    const { attribute } = this.state;
    attribute.expiryNotifications = expiryNotifications ?? [];

    this.setState({ attribute });
  }

  onNavigation(e: any, callback: any) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onCancel(e: any, source: any) {
    this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
  }

  stopBlockingNavAndClose(e: any) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Attribute");
    if (onCancel) onCancel(e);
  }

  isParent(attr?: any) {
    const { dialogMode, location } = this.props;
    const attribute = attr ? attr : this.state.attribute;
    const path = location ? location.pathname : "/";
    return (path.includes("parent") && !dialogMode) || (attribute && attribute.isParent);
  }

  onEditStateChange(hasEdit: any) {
    this.setState({ hasEdit });
  }

  render() {
    const {
      organization,
      orgSettings,
      theme,
      className,
      user,
      dialogMode,
      editMode,
      onCreate,
      onShowNewAttributeDialog,
      onShowNewCategoryDialog,
      location,
      onAllowNavigation,
      onBlockNavigation
    } = this.props;
    const {
      error,
      attribute,
      attributes,
      organizationDialogMode,
      categories,
      valid,
      hasEdit,
      saving,
      courses,
      trainingCourseSelected,
      expiryEnabledAutoOn
    } = this.state;
    // eslint-disable-next-line prefer-const
    let { expiryEnabled, proficiencyEnabled, trainingEnable } = this.state;

    const basePath = "/settings/competencies/new";

    let path = location ? location.pathname : "/";
    if (path === "/") path = basePath;

    const isParent = this.isParent();
    expiryEnabled = expiryEnabled === true || (attribute && attribute.hasExpiryDate === true);
    proficiencyEnabled =
      proficiencyEnabled === true || (attribute && attribute.hasProficiency === true);

    const actions: any = [
      {
        label: editMode ? "Save" : "Create",
        onClick: this.onSave.bind(this),
        primary: true,
        disabled: !valid || (editMode && !hasEdit)
      }
    ];

    const organizationData = organization ? organization : organizationDialogMode;

    if (!editMode && !dialogMode) {
      actions.push({
        label: "Save & Create another",
        variant: "filled",
        floatRight: theme.isBreakpointUp("md"),
        disabled: !valid,
        onClick: this.onSaveAndCreate.bind(this)
      });
    }

    actions.push({
      label: "Cancel",
      onClick: this.onCancel.bind(this)
    });

    return (
      <EditTemplate
        theme={theme}
        name='Competencies'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: this.existingAttribute,
          editing: attribute,
          members: [
            { name: "name" },
            { name: "description" },
            {
              name: "categories",
              detectEdit: (existing: any, editing: any) => !existing.compareCategories(editing)
            },
            {
              name: "childAttributes",
              arrayMatch: (i1: any, i2: any) => i1.attributeId === i2.attributeId
            },
            { name: "hasExpiryDate" },
            { name: "hasProficiency" },
            {
              name: "trainingCourses",
              arrayMatch: (i1: any, i2: any) => i1.id === i2.id
            },
            {
              name: "expiryNotifications",
              detectEdit: (existing: any, editing: any) => {
                const existingNotificationIndex = existing.expiryNotifications.map(
                  (n: any) => n.index
                );
                const editingNotificationIndex = editing.expiryNotifications.map(
                  (n: any) => n.index
                );
                return (
                  existingNotificationIndex.length !== editingNotificationIndex.length ||
                  !existingNotificationIndex.every((item: any) =>
                    editingNotificationIndex.includes(item)
                  )
                );
              }
            },
            { name: "importCode" }
          ]
        }}
      >
        <StyledAddEditAttributeSetting className={`${className ? className : ""}`}>
          <PaperOrDialog
            className={"add-edit-attribute-container-paper"}
            theme={theme}
            dialog={dialogMode}
            paperPadding={{ xs: "40px 24px", md: "56px 64px!important" }}
            title={
              editMode
                ? "Competency details"
                : dialogMode
                  ? "Create new competency"
                  : "Create competency"
            }
            titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
            titleSize={1}
            backButton={editMode}
            onBack={this.onCancel.bind(this)}
            actions={actions}
            justifyActions='start'
          >
            <Stack direction='column' spacing={4}>
              {error && (
                <Alert severity='error' sx={{ width: "100%" }}>
                  {error}
                </Alert>
              )}

              {/* {!dialogMode && !editMode && (
                <Stack className='attribute-type-container' direction='row' spacing={1} sx={{ width: "100%" }}>
                  <PillButtons
                    label='Competency type'
                    onChange={this.onAttributeTypeChange.bind(this)}
                    user={user}
                    organization={organizationData}
                    path={path}
                    basePath={basePath}
                    pages={AddEditAttributeView.subPages}
                    sx={{ alignSelf: "flex-start" }}
                  />
                  <TooltipIcon
                    text='Standard competencies are the most common. Use parent competencies to group standard competencies'
                    sx={{ marginTop: "25px!important" }}
                  />
                </Stack>
              )} */}

              <TextField
                className='attribute-name-text-field'
                label='Competency name (required)'
                autoComplete={false}
                autoFocus={true}
                clearable={false}
                sx={{ width: "100%" }}
                value={attribute ? attribute.name : null}
                onChange={this.onNameChange.bind(this)}
                validationMethod={() => this.isNameValid(attribute)}
                validationText='Please enter a valid name (more than 2 characters).'
              />
              <TextField
                className='description-text-field'
                label='Description'
                autoComplete={false}
                multiline={true}
                clearable={false}
                minRows={3}
                sx={{ width: "100%" }}
                value={attribute ? attribute.description : null}
                onChange={this.onDescriptionChange.bind(this)}
              />
              <MultiCategorySelector
                className='category-multi-selector'
                items={categories ? categories.categories : null}
                values={attribute ? attribute.categories : []}
                onNewItemClick={onShowNewCategoryDialog}
                getItemId={(cat: any) => cat.categoryId}
                onChange={this.onCategoriesChange.bind(this)}
                onSearch={this.onSearchCategories.bind(this)}
                onBlur={this.onCategoriesBlur.bind(this)}
              />

              {isParent && (
                <Fade in={true}>
                  <div>
                    <MultiAttributeSelector
                      label='Child competencies (required)'
                      helperText='Only standard competencies can be added as children'
                      items={
                        attributes && attribute
                          ? attributes.attributes.filter(
                              (a: any) =>
                                a.attributeDefinitionId !== attribute.attributeDefinitionId
                            )
                          : null
                      }
                      values={attribute ? attribute.childAttributes : []}
                      onNewItemClick={onShowNewAttributeDialog}
                      itemFilter={(attr: any) => !attr.isParent}
                      getItemId={(attr: any) => attr.attributeId}
                      onChange={this.onChildAttributesChange.bind(this)}
                      onSearch={this.onSearchChildAttributes.bind(this)}
                      onBlur={this.onChildAttributeBlur.bind(this)}
                      validationMethod={() => this.isChildAttributesValid(attribute)}
                      validationText='Please select child competencies'
                    />
                  </div>
                </Fade>
              )}

              <Stack className='add-edit-attribute-sub-container' gap='24px'>
                {!isParent && orgSettings && orgSettings.showProficiency && (
                  <Fade in={true}>
                    <div>
                      <StyledAttributePaper className='add-edit-attribute-paper'>
                        <Stack
                          className='add-edit-attribute-paper-stack'
                          sx={{ width: "100%" }}
                          justifyContent='space-between'
                          direction='row'
                        >
                          <Switch
                            label='Proficiency required'
                            checked={proficiencyEnabled}
                            onChange={this.onProficiencyEnabled.bind(this)}
                            disabled={!orgSettings || orgSettings.showProficiency === false}
                          />
                          <TooltipIcon
                            text={
                              orgSettings && orgSettings.showProficiency === false
                                ? 'Turn on proficiency in "Company" section to enable this feature'
                                : "When enabled, the competency will require a proficiency value to be added"
                            }
                            sx={{ margin: "-8px -8px 0 0" }}
                          />
                        </Stack>
                      </StyledAttributePaper>
                    </div>
                  </Fade>
                )}

                {!isParent && (
                  <Fade in={true}>
                    <div>
                      <StyledAttributePaper className='add-edit-attribute-paper'>
                        <Stack
                          className='add-edit-attribute-paper-stack'
                          sx={{ width: "100%" }}
                          justifyContent='space-between'
                          direction='row'
                        >
                          <Switch
                            label='Training'
                            checked={trainingEnable}
                            onChange={this.onTrainingEnable.bind(this)}
                            disabled={organizationData ? !organizationData.hasIntegration : true}
                          />
                          <TooltipIcon
                            text={
                              organizationData && organizationData.hasIntegration
                                ? "Training can be linked to this competency from any integration available. When training is completed, this competency will be updated, and the expiry date will automatically be updated if training has recurrence set"
                                : "Please contact the Klayo team to configure training integrations"
                            }
                            sx={{ margin: "-8px -8px 0 0" }}
                          />
                        </Stack>
                        {trainingEnable && (
                          <StyledCourseSelectorSection className='Klayo__course-selector-section'>
                            <MultiCourseSelector
                              label=''
                              helperText=''
                              organization={organizationData ? organizationData : null}
                              items={courses ? courses.courses : null}
                              values={trainingCourseSelected ? trainingCourseSelected : null}
                              getItemId={(course: any) => course.id}
                              getItemLabel={(course: any) => course.courseName}
                              onSearch={this.onSearchCourse.bind(this)}
                              onBlur={this.onLoadCourseBlur.bind(this)}
                              onChange={this.onCourseAttributeChange.bind(this)}
                              validationMethod={(e: any, value: any) =>
                                this.isTrainingCourseValid(trainingCourseSelected)
                              }
                              isValidateOnMount={true}
                              validationText='Please select training courses'
                            />
                            {expiryEnabledAutoOn && (
                              <Alert severity='info' sx={{ marginTop: "6px" }}>
                                Some selected training is recurrent, and expiry date is required for
                                this competency. The expiry date will be automatically updated when
                                training is completed
                              </Alert>
                            )}
                          </StyledCourseSelectorSection>
                        )}
                      </StyledAttributePaper>
                    </div>
                  </Fade>
                )}

                {!isParent && (
                  <Fade in={true}>
                    <div>
                      <StyledAttributePaper overflow='unset' className='add-edit-attribute-paper'>
                        <Stack
                          className='add-edit-attribute-paper-stack'
                          sx={{ width: "100%" }}
                          justifyContent='space-between'
                          direction='row'
                        >
                          <Switch
                            label='Expiry date required'
                            checked={expiryEnabled}
                            onChange={this.onExpiryEnabled.bind(this)}
                          />
                          <TooltipIcon
                            text='When enabled, the competency will require an expiry date to be added'
                            sx={{ margin: "-8px -8px 0 0" }}
                          />
                        </Stack>
                        {expiryEnabled && (
                          <>
                            <ExpiryNotificationSelector
                              theme={theme}
                              onChange={this.onExpiryNotificationChange.bind(this)}
                              values={attribute ? attribute.expiryNotifications : []}
                              sx={{ margin: "20px 0" }}
                              selectSx={{ width: { xs: "180px", sm: "218px" } }}
                            />

                            <Alert severity='info' sx={{ marginTop: "6px" }}>
                              The notifications will be sent to the employees and their managers
                            </Alert>
                          </>
                        )}
                      </StyledAttributePaper>
                    </div>
                  </Fade>
                )}
              </Stack>

              <Stack
                sx={{ width: "100%" }}
                spacing={2}
                justifyContent='space-between'
                alignItems='end'
                direction='row'
              >
                <TextField
                  label='Reference code'
                  autoComplete={false}
                  clearable={false}
                  sx={{ width: "100%" }}
                  value={attribute ? attribute.importCode : null}
                  onChange={this.onImportCodeChange.bind(this)}
                />
                <TooltipIcon
                  text='Your optional reference code for this competency, must be unique'
                  sx={{ paddingBottom: "1px" }}
                />
              </Stack>
            </Stack>
          </PaperOrDialog>
        </StyledAddEditAttributeSetting>
      </EditTemplate>
    );
  }
}

const StyledAttributePaper = styled(Paper)`
  &.add-edit-attribute-paper {
    padding: 24px;

    .add-edit-attribute-paper-stack {
      height: 24px;
    }
  }
`;

const StyledCourseSelectorSection = styled.div`
  &.Klayo__course-selector-section {
    margin-top: 24px;

    .klaro-chiparray {
      margin: 24px 0px 0px 0px;
      display: flex;
      gap: 16px;

      &.has-selected {
        margin: 24px 0px;
      }
    }

    .MuiChip-root {
      margin: 0px !important;
      height: 32px;
      padding: 6px 6px 6px 12px;
      gap: 6px;
    }

    .MuiChip-label {
      padding: 0px;
    }

    .MuiChip-deleteIcon {
      margin: 0px;
    }
  }
`;

const StyledAddEditAttributeSetting = styled.div`
  &.klayo__add-edit-attribute-setting {
    .attribute-type-container {
      margin-bottom: 44px;
    }

    .description-text-field {
      margin: 44px 0px 0px 0px;
    }

    .category-multi-selector {
      margin-top: 44px;
    }

    .add-edit-attribute-sub-container {
      margin-top: 40px;
    }

    .attribute-name-text-field {
      margin: 0px;
    }

    @media (max-width: 900px) {
      .MuiPaper-root.add-edit-attribute-container-paper {
        border-radius: 0px;
        border: none;

        h1 {
          color: ${KLAYO_COLORS.GreyGR900};
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: -0.3px;
          margin-bottom: 32px;
        }

        .description-text-field {
          margin: 38px 0px 0px 0px;
        }

        .category-multi-selector {
          margin-top: 38px;
        }

        .add-edit-attribute-sub-container {
          margin-top: 32px;
        }
      }
    }
  }
`;
