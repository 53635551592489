import { Badge, Grid } from "@mui/material";
import { cloneDeep, forIn } from "lodash";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppContext } from "../../common/AppContext";
import { PillTabs } from "../../components/PillTabs";
import { UserProfileBox } from "../../components/UserProfileBox";
import { ProfileAttributesSection } from "./ProfileAttributesSection";
import { ProfileGapsSection } from "./ProfileGapsSection";
import ProfileJobMatchingDetails from "./ProfileJobMatchingDetails";
import { ProfileJobMatchingSection } from "./ProfileJobMatchingSection";
import { ProfileJobSection } from "./ProfileJobSection";
import { ProfileTrainingSection } from "./ProfileTrainingSection";

export class ProfileLayout extends Component<any, any> {
  static contextType = AppContext;

  static defaultProps = {
    readOnly: true,
    myView: false,
    treeMode: false
  };

  static subPages = {
    job: {
      name: "Job",
      allowRoot: true,
      slug: "",
      paths: ["/"],
      component: ProfileJobSection,
      hasAccess: () => true,
      isDefault: () => true
    },
    competencies: {
      name: "Competencies",
      slug: "competencies",
      paths: ["/competencies", "/competencies/:id"],
      component: ProfileAttributesSection,
      hasAccess: () => true
    },
    gaps: {
      name: "Gaps",
      slug: "gaps",
      paths: ["/gaps", "/gaps/:id"],
      component: ProfileGapsSection,
      hasAccess: () => true
    }
  };

  constructor(props: any) {
    super(props);
  }

  getActivePages() {
    const { organization, myView, profile } = this.props;

    if (!organization || !profile) return ProfileLayout.subPages;

    const pages: { [key: string]: any } = cloneDeep(ProfileLayout.subPages);

    if (organization.hasIntegration) {
      pages.trainings = {
        name: "Training",
        slug: "trainings",
        paths: ["/trainings", "/trainings/:id"],
        component: ProfileTrainingSection,
        hasAccess: () => true
      };
    }
    if (organization.enableJobMatching) {
      pages.jobMatching = {
        name: "Job Matching",
        slug: "job-matching",
        paths: ["/job-matching", "/job-matching/:id"],
        component: ProfileJobMatchingSection,
        hasAccess: () => true,
        skipMyPrefix: true
      };
    }
    if (profile?.gapAttributes?.attributes?.length) {
      pages.gaps.render = ({ children }: any) => (
        <Badge
          invisible={false}
          color='error'
          variant='dot'
          slotProps={{
            badge: {
              style: {
                top: "3px",
                right: "-7px"
              }
            }
          }}
        >
          {children}
        </Badge>
      );
    }

    forIn(pages, (page: Record<any, any>) => {
      if (!page.skipMyPrefix) {
        page.name = myView ? `My ${page.name}` : page.name;
      }
    });

    return pages;
  }

  render() {
    const {
      organization,
      user,
      employee,
      trainings,
      theme,
      profile,
      basePath,
      location,
      readOnly,
      onDeleteAttribute,
      onFeedbackAction,
      onProficiencyChangeAction,
      onAddChangeAction,
      onViewDetails,
      onShowAttributeDetails,
      treeMode,
      onChangeViewMode,
      isInstructorViewing,
      onEditAttribute,
      onAddAttributeClickCb,
      myView
    } = this.props;

    let path = location.pathname;
    if (path === "/") path = basePath;

    const relativePath = path.replace(basePath, "");
    const relativePathSplit = relativePath.split("/");
    const activeTab = relativePathSplit[1] || "";

    const pages = this.getActivePages.bind(this)();

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserProfileBox
              user={employee}
              profile={profile}
              showViewDetailsButton={true}
              onViewDetails={onViewDetails}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>

        <PillTabs
          user={employee}
          organization={organization}
          theme={theme}
          path={basePath + (activeTab !== "" ? "/" + activeTab : "")}
          basePath={basePath || "/"}
          pages={pages}
          sx={{ margin: "41px 0" }}
        />

        <Switch>
          <Route
            exact={true}
            path={basePath + "/job-matching/:jobId"}
            render={(props) => {
              return (
                <div>
                  {React.createElement(ProfileJobMatchingDetails, {
                    ...props,
                    organization,
                    user,
                    employee,
                    theme,
                    profile,
                    basePath: basePath || "/"
                  })}
                </div>
              );
            }}
          />
          {Object.entries(pages)
            .filter(([_, subPage]: any) => !!subPage)
            .map(([key, subPage]: any) => {
              const paths = subPage.paths.map((p: any) => basePath + p);
              if (subPage.allowRoot === true)
                paths.push(subPage.slug === "" ? "" : "/" + subPage.slug);
              if (employee && subPage.isDefault && subPage.isDefault(employee))
                paths.push(basePath);

              return (
                <Route
                  key={key}
                  exact={true}
                  path={paths}
                  render={(props) => {
                    return (
                      <div>
                        {React.createElement(subPage.component, {
                          ...props,
                          organization,
                          user,
                          employee,
                          trainings,
                          theme,
                          profile,
                          readOnly: readOnly,
                          treeMode: treeMode,
                          onDeleteAttribute: onDeleteAttribute,
                          onFeedbackAction: onFeedbackAction,
                          isInstructorViewing: isInstructorViewing,
                          onProficiencyChangeAction: onProficiencyChangeAction,
                          onAddChangeAction: onAddChangeAction,
                          onShowAttributeDetails: onShowAttributeDetails,
                          onChangeViewMode,
                          onEditAttribute: onEditAttribute,
                          onAddAttributeClickCb: onAddAttributeClickCb,
                          myView,
                          basePath: basePath || "/"
                        })}
                      </div>
                    );
                  }}
                />
              );
            })}
          <Redirect to='/' />
        </Switch>
      </div>
    );
  }
}
