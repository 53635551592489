import { Grid } from "@mui/material";
import { Delayed, Theme } from "klayowebshared";
import { TeamFilterModel } from "../../common/models/TeamFilterModel";
import { TrainingBubbleChart } from "../../components/charts/TrainingBubbleChart";
import { Paper } from "../../components/Paper";
import { TeamTaskTable } from "../../components/table/TeamTaskTable";
import { TeamFilterSection } from "../../components/TeamFilterSection";
import { PAGE_SIZE_OPTIONS } from "../../constants";
import { Organization } from "../../data/Organization";
import { useTeamTrainingSection } from "../../hooks/useTeamTrainingSection";
import { TrainingPeopleListDialog } from "./TrainingPeopleListDialog";

type Props = {
  heading: string;
  theme: any;
  organization: Organization;
  filter: TeamFilterModel;
  groupId?: string;
  onEmployeeSelect: (e: any, employee: any) => void;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const TeamTrainingSection = ({
  heading,
  theme,
  organization,
  filter,
  groupId,
  basePath,
  basePathDepth,
  onEmployeeSelect,
  sortOrder,
  onSortOrderChange
}: Props) => {
  const {
    tableRef,
    sortModel,
    setSearch,
    isGapMode,
    onSwitchToGapMode,
    treeTrainings,
    classificationsSummary,
    onSortModelChange,
    isLoading,
    onPeopleSelect,
    onCloseTrainingPeopleListDialog,
    onGapSelect,
    trainingPeopleListDialogRef,
    search,
    handleSortOrderChange,
    getTreeDataPath,
    expandRowIds,
    onExpandRowChange,
    trainingTotal,
    onPaginationChange,
    pagination,
    isSummaryLoading
  } = useTeamTrainingSection({
    filter,
    groupId,
    basePath,
    basePathDepth,
    sortOrder,
    onSortOrderChange
  });

  return (
    <>
      <Grid container spacing={4} alignItems='stretch'>
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              title={heading}
              floatingTitle={true}
              padding={0}
              sx={{ height: "350px" }}
              overflow='visible'
            >
              <TrainingBubbleChart
                theme={theme}
                attributes={classificationsSummary}
                onAttributeSelect={isGapMode ? onGapSelect : onPeopleSelect}
                sx={{ height: "350px" }}
                {...(isGapMode
                  ? {
                      bubbleColor: Theme.getStyleVar("--torch-red-tr-050"),
                      bubbleTextColor: Theme.getStyleVar("--grey-gr-900")
                    }
                  : {})}
                isLoading={isSummaryLoading}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TeamFilterSection
              search={search}
              isGapMode={isGapMode}
              onSearch={(keyword: string) => setSearch(keyword)}
              searchPlaceholder='Search training or competency'
              onSwitchToGapMode={onSwitchToGapMode}
              theme={theme}
              sortOrder={sortOrder}
              onSortOrderChange={handleSortOrderChange}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Delayed>
              <TeamTaskTable
                rows={treeTrainings || []}
                tree
                theme={theme}
                ref={tableRef}
                showHeaderFromBreakpoint='sm'
                columns={[
                  {
                    type: TeamTaskTable.columns.summaryWithPeople,
                    visibleToBreakpoint: "sm"
                  },
                  {
                    type: TeamTaskTable.columns.course,
                    label: "Course / Competency",
                    visibleFromBreakpoint: "sm",
                    field: "courseName"
                  },
                  {
                    type: TeamTaskTable.columns.competencies,
                    visibleFromBreakpoint: "sm",
                    field: "competenciesCount",
                    label: "Competencies"
                  },
                  {
                    type: TeamTaskTable.columns.attributeEmployeesCount,
                    visibleFromBreakpoint: "sm",
                    field: "completedEmployeesCount"
                  },
                  {
                    type: TeamTaskTable.columns.gapEmployeesCount,
                    visibleFromBreakpoint: "sm",
                    field: "gapEmployeesCount"
                  }
                ]}
                onPeopleSelect={onPeopleSelect}
                onGapSelect={onGapSelect}
                showProficiency={organization && organization.showProficiency}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                noRowsMessage='Nothing here yet!'
                getTreeDataPath={getTreeDataPath}
                keyword={search}
                onExpandRowChange={onExpandRowChange}
                expandedRowIds={expandRowIds.trainings}
                paginationMode='server'
                totalCount={trainingTotal}
                onPaginationModelChange={onPaginationChange}
                paginationModel={{
                  pageSize: pagination.pageSize,
                  page: pagination.pageNumber
                }}
                loading={isLoading}
                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              />
            </Delayed>
          </Grid>
        </>
      </Grid>
      <TrainingPeopleListDialog
        ref={trainingPeopleListDialogRef}
        organization={organization}
        theme={theme}
        onClose={onCloseTrainingPeopleListDialog}
        onEmployeeSelect={onEmployeeSelect}
        groupId={groupId}
      />
    </>
  );
};
export { TeamTrainingSection };
