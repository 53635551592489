import {
  Autocomplete,
  Box,
  Fade,
  ListItemText,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs
} from "@mui/material";
import { Delayed, EditTemplate } from "klayowebshared";
import { cloneDeep, get, isEmpty, uniqBy } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { AttributeActionMenu } from "../../components/AttributeActionMenu";
import { Button } from "../../components/Button";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { RichTextEditor } from "../../components/RichTextEditor";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { AttributeTable } from "../../components/table/AttributeTable";
import { RelatedTaskInAttributeDialog } from "../../components/table/RelatedTasksInAttributeDialog";
import { TasksTable } from "../../components/table/TasksTable";
import { Data } from "../../data/Data";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { SettingsJob } from "../../data/settings/SettingsJob";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as LeftIcon } from "../../resources/images/icons-arrow-left.svg";
import { ReactComponent as RightIcon } from "../../resources/images/icons-arrow-right.svg";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { ReactComponent as CheckListIcon } from "../../resources/images/icons-checklist.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { trimToLowercase } from "../../utilities";
import { AttributeSelectorDialog } from "./AttributeSelectorDialog";
import { ConfirmDeleteTaskDialog } from "./ConfirmDeleteTaskDialog";
import { TaskSelectorDialog } from "./TaskSelectorDialog";
import { Theme } from "klayowebshared";
import { DomainSelector } from "../../components/selectors/DomainSelector";
import { DOMAIN_BLANK } from "../../constants/constants";
export class AddEditJobView extends Component<any, any> {
  static contextType = AppContext;
  existingJob: any;
  setMenuRef: any;
  menu: any;

  constructor(props: any) {
    super(props);

    this.state = {
      job: props.job,
      saving: false,
      error: props.error,
      step: 0,
      showAttributeSelectDialog: false,
      searchTask: null,
      searchCompetency: null,
      descriptionEditorState: RichTextEditor.createEditorState(""),
      originalEditorState: RichTextEditor.createEditorState(""),
      hasEdit: false,
      isShowTasksSelectDialog: false,
      isShowRelatedTasksInCompetencyDialog: false,
      tasksHasAttribute: [],
      isRemoveRelatedTasksInCompetencyDialog: false,
      deletingRowError: null,
      selectedDeleteTask: null,
      selectedAttr: null,
      domains: [],
      selectedDomain: DOMAIN_BLANK,
      loading: false
    };

    this.existingJob = new SettingsJob(props.job);

    this.setMenuRef = (menu: any) => {
      this.menu = menu;
    };
  }

  componentDidMount() {
    this.loadDetails();
    this.loadDomains();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
    const { domains, job } = this.state;

    if (
      (prevState.domains !== domains || prevState.job !== job) &&
      !isEmpty(domains) &&
      !isEmpty(job)
    ) {
      const selectedDomain =
        domains.find((d: any) => d.id === job.jobDefinitionDomainId) || DOMAIN_BLANK;
      this.setState({ selectedDomain });
    }
  }

  loadDetails() {
    const { job, step } = this.state;
    const jobDefinitionId = job ? job.jobDefinitionId : this.props.jobId;

    if (!jobDefinitionId) return;

    this.context.setLoading("loading", true);

    (Data as any)
      .get(this.context, "/JobDefinition/" + jobDefinitionId, {
        withCredentials: true
      })
      .then((response: any) => {
        const job = new SettingsJob(response.data.jobDefinition);
        this.existingJob = new SettingsJob(response.data.jobDefinition);

        this.setState({
          job,
          descriptionEditorState: RichTextEditor.createEditorState(job.description),
          originalEditorState: RichTextEditor.createEditorState(job.description)
        });
      })
      .catch((e: any) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("loading", false);
      });
  }
  loadDomains() {
    this.setState({ loading: true });
    (Data as any)
      .get(this.context, "/JobDefinition/Domains", {
        withCredentials: true
      })
      .then((response: any) => {
        const domainList = response.data.jobDefinitionDomain;

        const domains = [DOMAIN_BLANK, ...domainList];

        this.setState({
          domains,
          loading: false
        });
      })
      .catch((e: any) => {
        this.setState({ error: ErrorModel.parseServerError(e), domains: [], loading: false });
      });
  }
  onDomainChange(event: any, newValue: any) {
    const { job } = this.state;
    job.jobDefinitionDomainId = newValue?.id ? newValue : null;
    this.setState({
      job,
      selectedDomain: newValue
    });
  }
  onNameChange(e: any) {
    const { job } = this.state;
    job.name = e.target.value;

    this.setState({ job });
  }

  onDescriptionChange(text: any) {
    const { job } = this.state;
    job.description = text;

    this.setState({ job });
  }

  onDescriptionEditorStateChange(descriptionEditorState: any) {
    this.setState({ descriptionEditorState });
  }

  isValid(job: any, step: any) {
    if (!job || !this.isNameValid(job)) return false;
    if (step === 0) return true;
    if (step === 1) return true;
    return this.isAttributesValid(job);
  }

  isNameValid(job: any) {
    return job && job.name != null && job.name.length > 2;
  }

  isAttributesValid(job: any) {
    if (!job) return false;
    let isValid = true;

    job.attributes.forEach((a: any) => {
      if (a.isParent !== true && a.isChild !== true && !a.requirementType) isValid = false;
    });

    return isValid;
  }

  onSave() {
    const { onSave, editMode, onBlockNavigation } = this.props;
    const { job, step } = this.state;

    if (!editMode && step === 0 && this.isValid(job, 0)) this.setState({ step: 1 });
    if (!editMode && step === 1) this.setState({ step: 2 });

    if (editMode && step === 1) {
      // check requirementType in each attribute in job not undefined
      if (!this.isAttributesValid(job)) {
        this.setState({ step: 2 });
        return;
      }
    }

    if (onSave && (editMode || step === 2)) {
      if (onBlockNavigation) onBlockNavigation(false, "Job");
      this.setState({ saving: true });
      onSave(job);
    }
  }

  onShowAttributeSelectDialog(e: any) {
    this.setState({ showAttributeSelectDialog: true });
  }

  onSearchTask(e: any) {
    this.setState({ searchTask: e.target.value });
  }

  onSearchCompetency(e: any) {
    this.setState({ searchCompetency: e.target.value });
  }

  onAttributesAdded(addedAttributes: any) {
    const { job } = this.state;
    const childAttrs: any = [];
    addedAttributes.forEach((a: any) => {
      a.childAttributes.forEach((c: any) => {
        childAttrs.push(c);
        if (
          !addedAttributes.some((a: any) => a.attributeDefinitionId === c.attributeDefinitionId) &&
          !job.attributes.some((a: any) => a.attributeDefinitionId === c.attributeDefinitionId)
        )
          addedAttributes.push(c.toStandardAttribute());
      });
    });
    job.attributes = [...job.attributes, ...addedAttributes, ...childAttrs];
    this.setState({ job, showAttributeSelectDialog: false });
  }

  onAttributesEdit(addedAttributes: any) {
    const { job } = this.state;
    const childAttrs: any = [];
    addedAttributes.forEach((a: any) => {
      a.childAttributes.forEach((c: any) => {
        childAttrs.push(c);
        if (
          !addedAttributes.some((a: any) => a.attributeDefinitionId === c.attributeDefinitionId) &&
          !job.attributes.some((a: any) => a.attributeDefinitionId === c.attributeDefinitionId)
        )
          addedAttributes.push(c.toStandardAttribute());
      });
    });
    job.attributes = [...addedAttributes, ...childAttrs];
    this.setState({ job, showAttributeSelectDialog: false });
  }

  onTabChange(e: any, newStep: any) {
    this.setState({ step: newStep, error: null });
  }

  onAttributeDelete(e: any, attr: any) {
    const { job } = this.state;
    const { regulationTasks } = job;

    // Check if the attribute is used in any task
    const isUsedInTask = regulationTasks.some((task: any) => {
      return task.competencies.some(
        (c: any) => c.attributeDefinitionId === attr.attributeDefinitionId
      );
    });

    // Get list tasks has the attribute
    const tasksHasAttributeData = regulationTasks.filter((task: any) => {
      return task.competencies.some(
        (c: any) => c.attributeDefinitionId === attr.attributeDefinitionId
      );
    });

    if (isUsedInTask) {
      this.setState({
        isShowRelatedTasksInCompetencyDialog: true,
        tasksHasAttribute: tasksHasAttributeData
      });
      return;
    }

    job.attributes = job.attributes.filter(
      (a: any) =>
        !(a.attributeId === attr.attributeId && !a.isChild) &&
        a.parentAttributeId !== attr.attributeId
    );

    this.setState({ job });
  }

  onAttributeRequirementChange(attribute: any, requirementType: any) {
    attribute.requirementType = requirementType;
    attribute.type = requirementType.label;
    this.forceUpdate();
  }

  onCloseAttributeSelectDialog() {
    this.setState({ showAttributeSelectDialog: false });
  }

  onBack() {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  onNavigation(e: any, callback: any) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onCancel(e: any, source: any) {
    this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
  }

  stopBlockingNavAndClose(e: any) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Job");
    if (onCancel) onCancel(e);
  }

  rowHasAction(attr: any) {
    return true;
  }

  rowActionDisabled(attr: any) {
    const { job } = this.state;
    return (
      job.attributes.some(
        (a: any) =>
          a.isParent && a.childAttributes.some((a2: any) => a2.attributeId === attr.attributeId)
      ) !== false
    );
  }

  onEditStateChange(hasEdit: any) {
    this.setState({ hasEdit });
  }

  compareName(name: any, search: any) {
    return String(name || "")
      ?.toLowerCase()
      ?.includes(trimToLowercase(search || ""));
  }

  onCloseTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: false });
  }

  onShowTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: true });
  }

  onTasksAdded(tasks: any) {
    const { job } = this.state;
    job.regulationTasks = [...tasks];
    this.setState(job);

    const listRelatedAttributes: any = [];

    tasks.forEach((task: any) => {
      task.competencies.forEach((a: any) => {
        listRelatedAttributes.push(a);
      });
    });

    // remove duplicate attributes
    const uniqueAttributes = uniqBy(
      [...job.attributes, ...listRelatedAttributes],
      "attributeDefinitionId"
    ).map((a) => {
      return new SettingsAttribute(a);
    });

    this.onAttributesEdit(uniqueAttributes);
    this.onCloseTasksSelectDialog();
  }

  onDeleteTask(action: any, selectedTask: any) {
    const { job } = this.state;
    const { editMode } = this.props;

    const filterRegulationTaskList = job.regulationTasks.filter(
      (r: any) => r.id !== selectedTask.id
    );
    // Remove related competency in task
    const existingCompetencies = job.attributes;
    const deletedCompetencies = selectedTask.competencies;

    if (editMode) {
      this.setState({ selectedDeleteTask: selectedTask });
      // Check if the task is used in any competency
      const isUsedInTask = existingCompetencies.some((a: any) =>
        deletedCompetencies.some((d: any) => d.attributeDefinitionId === a.attributeDefinitionId)
      );

      if (isUsedInTask) {
        this.onShowRemoveRelatedTasksInCompetencyDialog();
        return;
      }
    }

    let filterCompetencies = [];
    filterCompetencies = existingCompetencies.filter(
      (a: any) =>
        !deletedCompetencies.some((d: any) => d.attributeDefinitionId === a.attributeDefinitionId)
    );

    filterRegulationTaskList.forEach((task: any) => {
      existingCompetencies.forEach((competency: any) => {
        // Check if any competency is still exist in the task, push it to the filterCompetencies
        if (
          task.competencies.some(
            (c: any) => c.attributeDefinitionId === competency.attributeDefinitionId
          )
        ) {
          filterCompetencies.push(competency);
        }
      });
    });

    filterCompetencies = uniqBy(filterCompetencies, "attributeDefinitionId");

    this.setState({
      job: { ...job, regulationTasks: filterRegulationTaskList, attributes: filterCompetencies }
    });
  }

  onRemoveRelatedTasksInCompetency() {
    const { job, selectedDeleteTask } = this.state;
    const filterRegulationTaskList = job.regulationTasks.filter(
      (r: any) => r.id !== selectedDeleteTask.id
    );
    // Remove related competency in task
    const existingCompetencies = job.attributes;
    const deletedCompetencies = selectedDeleteTask.competencies;

    let filterCompetencies = [];
    filterCompetencies = existingCompetencies.filter(
      (a: any) =>
        !deletedCompetencies.some((d: any) => d.attributeDefinitionId === a.attributeDefinitionId)
    );

    filterRegulationTaskList.forEach((task: any) => {
      existingCompetencies.forEach((competency: any) => {
        // Check if any competency is still exist in the task, push it to the filterCompetencies
        if (
          task.competencies.some(
            (c: any) => c.attributeDefinitionId === competency.attributeDefinitionId
          )
        ) {
          filterCompetencies.push(competency);
        }
      });
    });

    filterCompetencies = uniqBy(filterCompetencies, "attributeDefinitionId");

    this.setState({
      job: { ...job, regulationTasks: filterRegulationTaskList, attributes: filterCompetencies }
    });
    this.onHideRemoveRelatedTasksInCompetencyDialog();
  }

  onRemoveRelatedTasksButKeepCompetency() {
    const { job, selectedDeleteTask } = this.state;
    const filterRegulationTaskList = job.regulationTasks.filter(
      (r: any) => r.id !== selectedDeleteTask.id
    );
    this.setState({ job: { ...job, regulationTasks: filterRegulationTaskList } });
    this.onHideRemoveRelatedTasksInCompetencyDialog();
  }

  onCloseRelatedTasksInCompetencyDialog() {
    this.setState({ isShowRelatedTasksInCompetencyDialog: false });
  }

  onShowRelatedTasksInCompetencyDialog() {
    this.setState({ isShowRelatedTasksInCompetencyDialog: true });
  }

  isRegulationTasksSame(other: any) {
    const { job } = this.state;
    if (!other) return false;
    if (other.length !== job.regulationTasks.length) return false;

    for (const o of other) {
      const match = job.regulationTasks.find((r: any) => r.id === o.id);
      if (!match) return false;
    }
    return true;
  }

  isAttributesSame(other: any) {
    const { job } = this.state;
    if (!other) return false;
    const otherNoChildren = other.filter((a: any) => !a.isChild);
    if (other.length !== job.attributes.length) return false;

    for (const o of otherNoChildren) {
      const match = job.attributes.find((a: any) => a.attributeId === o.attributeId && !a.isChild);

      if (!match) return false;

      if (match.requirementType && o.requirementType) {
        if (match.requirementType.index !== o.requirementType.index) return false;
      }

      if (match.isMinimum !== o.isMinimum) return false;
    }
    return true;
  }

  onShowRemoveRelatedTasksInCompetencyDialog() {
    this.setState({ isRemoveRelatedTasksInCompetencyDialog: true });
  }

  onHideRemoveRelatedTasksInCompetencyDialog() {
    this.setState({ isRemoveRelatedTasksInCompetencyDialog: false });
  }

  onCloseActionMenu() {
    if (this.menu) this.menu.close();

    this.setState({
      selectedAttr: null
    });
  }

  onAttributeAction(e: any, job: any) {
    if (this.menu) this.menu.open(e.target);

    this.setState({
      selectedAttr: job
    });
  }

  onUpdateMinimum(attr: any, isMark: boolean) {
    const previousJob = cloneDeep(this.state.job);

    this.setState(
      {
        job: {
          ...this.state.job,
          attributes: this.state.job.attributes.map((a: any) => {
            if (a.attributeId === attr.attributeId) {
              const updateAttr = cloneDeep(a);
              updateAttr.isMinimum = isMark;
              return updateAttr;
            }
            return a;
          })
        }
      },
      () => {
        const hasChanges = this.state.job.attributes.some((newAttr: any) => {
          const oldAttr = previousJob.attributes.find(
            (a: any) => a.attributeId === newAttr.attributeId
          );
          return oldAttr && oldAttr.isMinimum !== newAttr.isMinimum;
        });

        if (hasChanges) {
          this.setState({ hasEdit: true });
        }
      }
    );
  }

  renderActionMenu() {
    const { selectedAttr } = this.state;

    return (
      <div>
        {this.props?.organization?.enableJobMatching && (
          <MenuItem onClick={() => this.onUpdateMinimum(selectedAttr, !selectedAttr.isMinimum)}>
            <ListItemText>
              {selectedAttr?.isMinimum ? "Unmark as a minimum" : "Mark as a minimum"}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={(e) => this.onAttributeDelete(e, selectedAttr)}>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </div>
    );
  }

  render() {
    const {
      organization,
      user,
      theme,
      attributes,
      editMode,
      onCreate,
      onLoadAttributes,
      onShowNewAttributeDialog,
      onAllowNavigation,
      onBlockNavigation
    } = this.props;
    const {
      error,
      job,
      step,
      showAttributeSelectDialog,
      descriptionEditorState,
      hasEdit,
      saving,
      isShowTasksSelectDialog,
      isShowRelatedTasksInCompetencyDialog,
      tasksHasAttribute,
      isRemoveRelatedTasksInCompetencyDialog,
      deletingRowError,
      searchCompetency,
      searchTask
    } = this.state;

    const filterItemsAttribute = [
      { id: "search", columnField: "attribute", operatorValue: "contains", value: searchCompetency }
    ];
    const filterItemsTasks = [
      { id: "search", columnField: "name", operatorValue: "contains", value: searchTask }
    ];

    const filterCompetencies =
      job && job.attributes
        ? job.attributes?.filter((p: any) => {
            const matchChildren = p?.childAttributes?.some((c: any) =>
              this.compareName(c?.name, searchCompetency)
            );
            const matchAttribute = this.compareName(p?.name, searchCompetency);
            return matchAttribute || matchChildren;
          })
        : [];

    const filterJobTasks = get(job, "regulationTasks", []).filter((task: any) => {
      if (searchTask) {
        const keyword = trimToLowercase(searchTask || "");
        const taskName = trimToLowercase(task?.name || "");
        return taskName?.includes(keyword);
      } else {
        return true;
      }
    });

    return (
      <EditTemplate
        theme={theme}
        name='Job'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: this.existingJob,
          editing: job,
          members: [
            { name: "name" },
            {
              name: "description",
              detectEdit: (existing: any, editing: any, isNew: any) => {
                if (isNew) return editing.description && editing.description.trim() !== "<p></p>";
                return (
                  existing.description !== editing.description &&
                  existing.description + "\n<p></p>" !== editing.description
                );
              }
            },
            {
              name: "regulationTasks",
              detectEdit: (existing: any, editing: any) =>
                !this.isRegulationTasksSame(existing.regulationTasks)
            },
            {
              name: "attributes",
              detectEdit: (existing: any, editing: any) =>
                !this.isAttributesSame(existing.attributes)
            },
            {
              name: "jobDefinitionDomainId",
              detectEdit: (existing: any, editing: any) => {
                return existing.jobDefinitionDomainId !== editing.jobDefinitionDomainId;
              }
            }
          ]
        }}
      >
        <PaperOrDialog
          theme={theme}
          dialogToBreakpoint='md'
          disabledToBreakpoint='md'
          paperPadding={{ xs: "46px 24px", md: "60px" }}
          title={editMode ? "Job details" : "Create job"}
          titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
          titleSize={1}
          backButton={editMode}
          onBack={this.onCancel.bind(this)}
        >
          <Stack direction='column' spacing={4}>
            {error && (
              <Alert severity='error' sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}

            {!editMode ? (
              <Stepper activeStep={step}>
                <Step sx={{ cursor: step !== 0 ? "pointer" : "default" }}>
                  <StepLabel>Job details</StepLabel>
                </Step>
                <Step sx={{ cursor: step !== 0 ? "pointer" : "default" }}>
                  <StepLabel>Tasks</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Job competencies</StepLabel>
                </Step>
              </Stepper>
            ) : (
              <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    centered={true}
                    value={step}
                    onChange={this.onTabChange.bind(this)}
                    variant='fullWidth'
                    sx={{ width: "100%" }}
                  >
                    <Tab label='Information' value={0} />
                    <Tab label='Tasks' value={1} />
                    <Tab label='Competencies' value={2} />
                  </Tabs>
                </Box>
              </div>
            )}

            {step === 0 && (
              <Fade in={true}>
                <Stack direction='column' spacing={4}>
                  <TextField
                    label='Job title (required)'
                    placeholder='e.g. Marketing Manager'
                    autoComplete={false}
                    autoFocus={true}
                    validationMethod={(e: any, value: any) => this.isNameValid(job)}
                    validationText='Please enter a valid name (more than 2 characters)'
                    clearable={false}
                    sx={{ width: "100%" }}
                    value={job ? job.name : null}
                    onChange={this.onNameChange.bind(this)}
                  />
                  <RichTextEditor
                    label='Job description'
                    editorState={descriptionEditorState}
                    onChange={this.onDescriptionChange.bind(this)}
                    onChangeEditorState={this.onDescriptionEditorStateChange.bind(this)}
                  />

                  <DomainSelector
                    value={this.state.selectedDomain}
                    items={this.state.domains || []}
                    callApi={false}
                    multiple={false}
                    getItemLabel={(option: any) => option.name}
                    onChange={this.onDomainChange.bind(this)}
                    disabled={saving}
                    loading={this.state.loading}
                    disabledSearch={true}
                  />
                </Stack>
              </Fade>
            )}

            {step === 1 && (
              <Fade in={true}>
                <div>
                  <Stack direction='column' spacing={2}>
                    <div>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <TextField
                          value={searchTask}
                          dense={true}
                          placeholder='Search task'
                          autoComplete={false}
                          leadingIcon={<SearchIcon />}
                          disabled={
                            get(job, "regulationTasks", []).length > 0
                              ? job.regulationTasks.length === 0
                              : true
                          }
                          onChange={this.onSearchTask.bind(this)}
                          sx={{ minWidth: "300px" }}
                        />

                        <Button
                          onClick={this.onShowTasksSelectDialog.bind(this)}
                          size='md'
                          variant='outlined'
                          startIcon={<AddIcon />}
                          label='Add tasks'
                        />
                      </Stack>

                      <Delayed>
                        <TasksTable
                          rows={filterJobTasks}
                          pagination={false}
                          theme={theme}
                          showFooter={false}
                          rowHasAction={true}
                          filterItems={filterItemsTasks}
                          hideFirstLastBorder={true}
                          noRowsComponent={
                            <TablePlaceholder
                              text='Use Add tasks button to link tasks for this job.'
                              icon={<CheckListIcon />}
                            />
                          }
                          dense={true}
                          paper={false}
                          columns={[
                            { type: TasksTable.columns.name },
                            { type: TasksTable.columns.actions }
                          ]}
                          onPositionAction={this.onDeleteTask.bind(this)}
                          isUsingDeleteIcon={true}
                        />
                      </Delayed>
                    </div>
                  </Stack>
                </div>
              </Fade>
            )}

            {step === 2 && (
              <Fade in={true}>
                <div>
                  <Stack direction='column' spacing={2}>
                    <div>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <TextField
                          value={searchCompetency}
                          dense={true}
                          placeholder='Search competency'
                          disabled={job && job.attributes.length === 0}
                          autoComplete={false}
                          leadingIcon={<SearchIcon />}
                          onChange={this.onSearchCompetency.bind(this)}
                          sx={{ minWidth: "300px" }}
                        />

                        <Button
                          onClick={this.onShowAttributeSelectDialog.bind(this)}
                          size='md'
                          variant='outlined'
                          startIcon={<AddIcon />}
                          label='Add competencies'
                        />
                      </Stack>
                    </div>
                    <div>
                      <AttributeTable
                        // rows={job ? job.attributes : null}
                        rows={filterCompetencies}
                        pagination={false}
                        theme={theme}
                        showFooter={false}
                        rowHasAction={this.rowHasAction.bind(this)}
                        rowActionDisabled={this.rowActionDisabled.bind(this)}
                        filterItems={filterItemsAttribute}
                        hideFirstLastBorder={true}
                        noRowsComponent={
                          <TablePlaceholder
                            text='Use Add competencies button to select competencies for this job'
                            icon={<AttributesIcon />}
                          />
                        }
                        dense={true}
                        tree={true}
                        onAttributeDelete={this.onAttributeDelete.bind(this)}
                        onRequirementChange={this.onAttributeRequirementChange.bind(this)}
                        paper={false}
                        columns={[
                          {
                            type: AttributeTable.columns.attribute,
                            label: "Competency",
                            showJobMatchingIcon: true
                          },
                          {
                            type: AttributeTable.columns.requirementType,
                            label: "Priority",
                            selectable: true,
                            selectableOnChild: false
                          },
                          {
                            type: AttributeTable.columns.requiredProficiency,
                            label: "Min Proficiency"
                          },
                          { type: AttributeTable.columns.actionsMore }
                        ]}
                        onAttributeAction={this.onAttributeAction.bind(this)}
                        organization={organization}
                      />

                      <AttributeActionMenu
                        ref={this.setMenuRef}
                        onClose={this.onCloseActionMenu.bind(this)}
                        onRender={this.renderActionMenu.bind(this)}
                      />
                    </div>
                  </Stack>
                </div>
              </Fade>
            )}

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button onClick={this.onCancel.bind(this)} label='Cancel' />

              <Stack direction='row' spacing={2}>
                {step > 0 && !editMode && (
                  <Button
                    variant='outlined'
                    onClick={this.onBack.bind(this)}
                    startIcon={<LeftIcon />}
                    label='Back'
                  />
                )}
                <Button
                  onClick={this.onSave.bind(this)}
                  variant='filled'
                  disabled={!this.isValid(job, step) || (editMode && !hasEdit)}
                  endIcon={!editMode && step !== 2 ? <RightIcon /> : null}
                  label={editMode ? "Save" : step !== 2 ? "Continue" : "Create"}
                />
              </Stack>
            </Stack>
          </Stack>

          {showAttributeSelectDialog && (
            <AttributeSelectorDialog
              theme={theme}
              attributes={attributes}
              excludedAttributes={job ? job.attributes : null}
              allowChildren={false}
              onLoadAttributes={onLoadAttributes}
              onShowNewAttributeDialog={onShowNewAttributeDialog}
              onClose={this.onCloseAttributeSelectDialog.bind(this)}
              onSave={this.onAttributesAdded.bind(this)}
            />
          )}

          {isShowTasksSelectDialog && (
            <TaskSelectorDialog
              theme={theme}
              existingTasks={get(job, "regulationTasks", [])}
              onClose={this.onCloseTasksSelectDialog.bind(this)}
              onSave={this.onTasksAdded.bind(this)}
              subHeader='Select one or more tasks to be added to the job'
              placeholder='Search and add'
            />
          )}

          {isShowRelatedTasksInCompetencyDialog && (
            <RelatedTaskInAttributeDialog
              type={4}
              onClose={this.onCloseRelatedTasksInCompetencyDialog.bind(this)}
              theme={theme}
              tasksHasAttribute={tasksHasAttribute}
              {...this.props}
            />
          )}

          {isRemoveRelatedTasksInCompetencyDialog && (
            <ConfirmDeleteTaskDialog
              theme={theme}
              title={`Delete`}
              content={
                <div>
                  Would you like to remove all associated competencies as well? These competencies
                  are not required by any other tasks.
                </div>
              }
              onClose={this.onHideRemoveRelatedTasksInCompetencyDialog.bind(this)}
              onConfirm={this.onRemoveRelatedTasksInCompetency.bind(this)}
              onNotConfirm={this.onRemoveRelatedTasksButKeepCompetency.bind(this)}
            />
          )}
        </PaperOrDialog>
      </EditTemplate>
    );
  }
}
