import { Box, Chip, Grid, Stack } from "@mui/material";

import { JobMatchingFilterSection } from "../../components/JobMatchingFiltersSection";
import { SectionLoader } from "../../components/SectionLoader";
import { JobMatchingCompetencySelector } from "../../components/selectors/JobMatchingCompetencySelector";
import { JobMatchingTable } from "../../components/table/JobMatchingTable";
import { Organization } from "../../data/Organization";
import { useJobMatching } from "../../hooks/useJobMatching";
import { ReactComponent as RemoveIcon } from "../../resources/images/icons-remove.svg";

type Props = {
  heading: string;
  theme: any;
  organization: Organization;
  employee: any;
  basePath: string;
  onEmployeeSelect: (e: any, employee: any) => void;

  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const ProfileJobMatchingSection = ({ theme, employee, basePath, onSortOrderChange }: Props) => {
  const {
    tableRef,
    search,
    handleSearchChange,
    showMinNeeded,
    handleMinNeededChange,
    handleSortOrderChange,
    filteredRows,
    isLoading,
    selectedAttributes,
    handleAttributesAdded,
    isOpenCompetencyFilterModal,
    onCloseFilterModal,
    onSearchCompetency,
    onShowFilterModal,
    attributes,
    handleRemoveAttribute,
    handleClearAll,
    handleRowClick,
    isCompetencyFetching
  } = useJobMatching({
    employee,
    onSortOrderChange,
    basePath
  });

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} sx={{ marginBottom: "6px" }}>
          <Stack spacing={2}>
            <JobMatchingFilterSection
              search={search}
              onSearch={handleSearchChange}
              searchPlaceholder='Search job'
              isMinNeededMet={showMinNeeded}
              onSwitchToMinNeededMet={handleMinNeededChange}
              onSortOrderChange={handleSortOrderChange}
              theme={theme}
              onAttributesAdded={handleAttributesAdded}
              selectedAttributes={selectedAttributes}
              onShowFilterModal={onShowFilterModal}
            />

            {selectedAttributes.length > 0 && (
              <Stack
                direction='row'
                alignItems='center'
                flexWrap='wrap'
                sx={{
                  margin: "-4px 0 0 -4px"
                }}
              >
                {selectedAttributes.map((attribute: any) => (
                  <Chip
                    key={attribute.id}
                    label={
                      <span>
                        Competency: <b>{attribute.name}</b>
                      </span>
                    }
                    onDelete={() => handleRemoveAttribute(attribute)}
                    deleteIcon={<RemoveIcon />}
                    sx={{
                      margin: "4px 0 0 4px"
                    }}
                  />
                ))}
                <Box
                  component='span'
                  onClick={handleClearAll}
                  sx={{
                    color: "#0027da",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline"
                    },
                    margin: "4px 0 0 4px"
                  }}
                >
                  Clear all
                </Box>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        {isLoading ? (
          <SectionLoader />
        ) : (
          <JobMatchingTable
            ref={tableRef}
            rows={filteredRows}
            columns={[
              {
                type: JobMatchingTable.columns.summary
              },
              {
                type: JobMatchingTable.columns.job,
                visibleFromBreakpoint: "md"
              },
              {
                type: JobMatchingTable.columns.minNeeded,
                visibleFromBreakpoint: "md"
              },
              {
                type: JobMatchingTable.columns.allCompetencies,
                visibleFromBreakpoint: "md"
              },
              {
                type: JobMatchingTable.columns.overallMatching,
                visibleFromBreakpoint: "md"
              }
            ]}
            sortable={true}
            theme={theme}
            className='w-full'
            sx={{
              marginTop: "14px"
            }}
            showHeaderFromBreakpoint='md'
            onJobSelect={handleRowClick}
            rowHasAction={true}
            selectable={true}
            dense={true}
          />
        )}
      </Grid>

      <JobMatchingCompetencySelector
        open={isOpenCompetencyFilterModal}
        attributes={attributes}
        theme={theme}
        excludedAttributes={employee?.attributes?.filter((a: any) => !a.isChild)}
        onClose={onCloseFilterModal}
        onSave={handleAttributesAdded}
        initialSelectedAttributes={selectedAttributes}
        onSearch={onSearchCompetency}
        isLoading={isCompetencyFetching}
      />
    </Box>
  );
};

export { ProfileJobMatchingSection };
