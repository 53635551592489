import { Stack } from "@mui/material";
import { BreakpointTemplate, Switch } from "klayowebshared";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import useDebounce from "../hooks/useDebounce";
import { ReactComponent as FilterIcon } from "../resources/images/icons-filter.svg";
import { ReactComponent as SearchIcon } from "../resources/images/icons-search.svg";
import { Button } from "./Button";
import { TextField } from "./TextField";

type Props = {
  search?: string;
  onSearch: (search: string) => void;
  searchPlaceholder: string;
  isMinNeededMet: boolean;
  onSwitchToMinNeededMet: (e: ChangeEvent<HTMLInputElement>) => void;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
  onFilterClick?: () => void;
  theme?: any;
  definitions?: any;
  employeeAttributes?: any;
  onAttributeChange?: (e: any, attribute: any, alreadyHasAttribute: boolean) => void;
  attributes?: any[];
  employee?: any;
  onLoadAttributes?: () => void;
  onShowNewAttributeDialog?: () => void;
  onAttributesAdded?: (attributes: any[]) => void;
  selectedAttributes?: any[];
  onShowFilterModal: () => void;
};

const JobMatchingFilterSection = ({
  search,
  onSearch,
  searchPlaceholder,
  isMinNeededMet,
  onSwitchToMinNeededMet,
  onFilterClick,
  theme,
  attributes,
  employee,
  onLoadAttributes,
  onShowNewAttributeDialog,
  onAttributesAdded,
  selectedAttributes = [],
  onShowFilterModal
}: Props) => {
  const [searchCurrent, setSearchCurrent] = useState("");
  const isFirstRender = useRef(true);

  const debounceSearch = useDebounce(searchCurrent.trim(), 500);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setSearchCurrent(search ?? "");
      return;
    }

    onSearch(debounceSearch);
  }, [debounceSearch]);

  return (
    <>
      <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
        <BreakpointTemplate
          theme={theme}
          breakpoint='sm'
          to={
            <TextField
              value={searchCurrent}
              dense={true}
              placeholder={searchPlaceholder}
              fullWidth={true}
              autoComplete={false}
              leadingIcon={<SearchIcon />}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchCurrent(e.target.value)}
              sx={{ width: "100%" }}
            />
          }
          from={
            <Stack
              direction='row'
              spacing={3}
              alignItems='center'
              sx={{ flex: 1, maxWidth: { md: "480px" } }}
            >
              <TextField
                value={searchCurrent}
                dense={true}
                placeholder={searchPlaceholder}
                fullWidth={true}
                autoComplete={false}
                leadingIcon={<SearchIcon />}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchCurrent(e.target.value)}
              />
              <Button
                className='klayo-button_label'
                label='Filters'
                theme={theme}
                size='md'
                startIcon={<FilterIcon />}
                sx={{
                  width: "180px"
                }}
                onClick={onShowFilterModal}
              />
            </Stack>
          }
          sx={{
            flex: 1
          }}
        />

        <BreakpointTemplate
          theme={theme}
          breakpoint='sm'
          to={null}
          from={
            <Switch
              label='Minimum met'
              checked={isMinNeededMet}
              onChange={onSwitchToMinNeededMet}
            />
          }
        />
      </Stack>
    </>
  );
};

export { JobMatchingFilterSection };
