import { AxiosRequestConfig } from "axios";
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query";
import axiosClient from "../common/AxiosClient";

type MutationFnType<TVariables> = (variables: TVariables) => Promise<any>;

interface UseAppMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> extends UseMutationOptions<TData, TError, TVariables, TContext> {
  config?: AxiosRequestConfig; // Optional axios config if you are using axios
}

export function useAppMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  method: "get" | "post" | "put" | "delete",
  url: string,
  options: UseAppMutationOptions<TData, TError, TVariables, TContext> = {}
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { ...mutationOptions } = options;

  return useMutation<TData, TError, TVariables, TContext>({
    mutationFn: (body) => axiosClient[method](url, body, options?.config),
    ...mutationOptions
  });
}
