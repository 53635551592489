import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { JobLinkedTable } from "../../components/table/JobLinkedTable";
import { KLAYO_COLORS } from "../../themes";

import axiosClient from "../../common/AxiosClient";
import { useHistory } from "react-router";
import { useAppQuery } from "../../hooks/useAppQuery";
import { Box } from "@mui/material";
import { SectionLoader } from "../../components/SectionLoader";
import { IJobJinked } from "../../interfaces/Competency";

interface LinkedJobDialogProps {
  open: boolean;
  onClose: () => void;
  row?: {
    attributeDefinitionId?: string;
  };
  theme: any;
}

const LinkedJobDialog: React.FC<LinkedJobDialogProps> = ({ open, onClose, row, theme }) => {
  const history = useHistory();

  const { data: linkedJobRes, isFetching } = useAppQuery<IJobJinked>(
    `/AttributeDefinition/${row?.attributeDefinitionId}/linkedjobs`,
    {
      enabled: !!(open && row?.attributeDefinitionId)
    }
  );

  const linkedJobs = useMemo(() => {
    if (!linkedJobRes) return [];

    return linkedJobRes?.jobs || [];
  }, [linkedJobRes]);

  const handleJobClick = (e: any, job: any) => {
    history.push(`/settings/jobs/edit/${job.jobDefinitionId}`);
    onClose();
  };

  return (
    <StyledTrainingAttributesDialog
      className='klayo__training-attribute-dialog'
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      actions={[
        {
          label: "Close",
          variant: "filled",
          onClick: onClose
        }
      ]}
    >
      <h1>Linked Jobs</h1>
      <h2>This competency is linked to the following jobs:</h2>

      {isFetching ? (
        <SectionLoader />
      ) : (
        <JobLinkedTable
          showFooterRowCount={true}
          pagination={true}
          rowHasAction={true}
          minHeight='56px'
          pageSize={5}
          showHeaderFromBreakpoint='md'
          hideFirstLastBorder={true}
          theme={theme}
          dense={true}
          paginationMode='client'
          columns={[{ type: JobLinkedTable.columns.name }]}
          paper={false}
          onRowClick={handleJobClick}
          rows={linkedJobs}
        />
      )}
    </StyledTrainingAttributesDialog>
  );
};

const StyledTrainingAttributesDialog = styled(Dialog)`
  &.klayo__training-attribute-dialog {
    h1 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.5px;
      margin-top: 0px;
      margin-bottom: 32px;
    }

    h2 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 0px;
      margin-bottom: 24px;
    }

    .main-title {
      font-weight: 700;
    }

    .MuiDialogContent-root {
      padding: 40px 40px 0px 40px;
    }

    .MuiPaper-root {
      max-width: 640px;
    }
  }
`;

export default LinkedJobDialog;
