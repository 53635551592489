import { User } from "../users/User";
import { AbstractAttribute } from "./AbstractAttribute";
export class FeedbackAttribute extends AbstractAttribute {
  static type: any = {
    newAttribute: {
      label: "New competency",
      description: "Add new competency request"
    },
    proficiencyChange: {
      label: "Request changes",
      description: "Request changes"
    },
    attributeDefinitionChange: {
      label: "Competency definition change",
      description: "Competency definition change"
    },
    adminRequest: {
      label: "Admin request",
      description: "Admin request"
    },
    expiredAttribute: {
      label: "Competency renew",
      description: "Competency renew"
    },
    completedTraining: {
      label: "Completed training",
      description: "Completed training"
    },
    participatedInPractical: {
      label: "Participated in a practical",
      description: "Participated in a practical"
    }
  };

  static state = {
    review: {
      label: "Pending feedback"
    },
    submitted: {
      label: "Feedback submitted"
    },
    approved: {
      label: "Approved"
    },
    declined: {
      label: "Declined"
    }
  };

  static approvedStatus = {
    submitted: {
      label: "Feedback submitted"
    },
    pending: {
      label: "Pending feedback"
    }
  };

  employeeAttributeValidationRequestId: any;
  attributeDefinitionName: any;
  requestDateTime: any;
  proficiencyValue: any;
  currentProficiencyValue: any;
  validated: any;
  type: any;
  typeLabel: any;
  state: any;
  stateLabel: any;
  employeeFirstName: any;
  employeeLastName: any;
  employeeFullName: any;
  jobName: any;
  approvedStatus: any;
  employee: any;

  constructor(index: any, data: any) {
    super(data.employeeAttributeValidationRequestId, data);

    this.employeeAttributeValidationRequestId = data.employeeAttributeValidationRequestId;
    this.employeeAttributeId = data.employeeAttributeId;
    this.attributeDefinitionName = data.attributeDefinitionName;
    this.attributeName = data.attributeDefinitionName;
    this.attribute = data.attributeDefinitionName;
    this.requestDateTime = data.requestDateTime;
    this.proficiencyValue = data.proficiencyValue;
    this.currentProficiencyValue = data.employeeAttributeProficiencyValue;
    this.validated = data.validated;
    this.type = Object.values(FeedbackAttribute.type)[data.requestType];
    this.typeLabel = this.type ? this.type.label : "";
    this.state = Object.values(FeedbackAttribute.state)[data.requestState];
    this.stateLabel = this.state ? this.state.label : "";
    this.expiryDate = data.expiryDate;
    this.employeeFirstName = data.employeeFirstName;
    this.employeeLastName = data.employeeLastName;
    this.employeeFullName = data.employeeFullName;
    this.jobName = data.jobName;
    this.approvedStatus = Object.values(FeedbackAttribute.approvedStatus)[data.feedbackStatus];

    this.employee = new User({
      data: {
        id: data.employeeId,
        employeeId: data.employeeId,
        firstName: data.employeeFirstName,
        lastName: data.employeeLastName,
        fullName: data.employeeFullName,
        hasProfilePicture: data.employeeHasProfilePic,
        jobTitle: data.jobName
      }
    });
  }
}
