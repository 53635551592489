import React from "react";
import { Table } from "./Table";

export class JobLinkedTable extends Table {
  static columns = {
    name: {
      field: "name",
      label: "Job definitions"
    },
    description: {
      field: "description",
      label: "Description"
    },
    jobAttributes: {
      field: "jobAttributes",
      label: "Job Attributes"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    noRowsMessage: "No rows, yet"
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state
    };
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(params: any, e: any) {
    const { onRowClick } = this.props;
    if (onRowClick) {
      onRowClick(e, params.row);
    }
  }

  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === JobLinkedTable.columns.name)
          this.columns.push({
            field: "name",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params: any) => params.row.name,
            cellRenderer: (params: any) => <div>{params.row.name}</div>
          });
        if (c.type === JobLinkedTable.columns.description)
          this.columns.push({
            field: "description",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params: any) => params.row.description,
            cellRenderer: (params: any) => <div>{params.row.description}</div>
          });
        // if (c.type === JobLinkedTable.columns.jobAttributes)
        //   this.columns.push({
        //     field: "jobDefinitions",
        //     headerName: c.label || c.type.label,
        //     sortable: sortable,
        //     filterable: false,
        //     disableReorder: true,
        //     flex: 1,
        //     valueGetter: (params: any) => params.row.name,
        //     cellRenderer: (params: any) => <div>{params.row.name}</div>
        //   });
      }
    });
  }
}
