import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { Abstract_Attribute } from "../constants";
import { JobAttributeList } from "../data/attributes/JobAttributeList";
import { useAppMutation } from "./useAppMutation";
import { useAppQuery } from "./useAppQuery";

interface JobAttribute {
  gapReason: string;
  missing?: boolean;
  [key: string]: any;
}

interface JobMatchingDetail {
  jobName: string;
  description: string;
  jobAttributes: JobAttribute[];
  numberOfJobCompetencies: number;
  numberOfMatchingJobCompetencies: number;
  numberOfMatchingMinimumJobCompetencies: number;
  numberOfMinimumJobCompetencies: number;
}

interface JobMatchingDetailResponse {
  jobMatchingDetail: JobMatchingDetail;
}

interface Employee {
  employeeId: string;
}

interface UseJobMatchingDetailsProps {
  jobId: string | undefined;
  employee: Employee | null;
}

export const useJobMatchingDetails = ({ jobId, employee }: UseJobMatchingDetailsProps) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showGapsOnly, setShowGapsOnly] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);

  const queryEnabled = Boolean(jobId && employee?.employeeId);

  const { data, isLoading, error } = useAppQuery<JobMatchingDetailResponse>(
    `/Profile/JobMatching/${jobId}/${employee?.employeeId}`,
    {
      config: {
        params: {}
      },
      enabled: queryEnabled
    }
  );

  // Add print query
  const { mutateAsync: printJobMatching, isLoading: isPrintLoading } = useAppMutation<
    any,
    unknown,
    void
  >("get", `/Profile/JobMatching/${jobId}/${employee?.employeeId}/Print`);

  const handleOpenPrintModal = () => {
    setShowPrintModal(true);
  };

  const handleClosePrintModal = () => {
    setShowPrintModal(false);
  };

  const handleConfirmPrint = async () => {
    try {
      await printJobMatching();
      handleClosePrintModal();
    } catch (error) {}
  };

  const allJobAttributes = useMemo(() => {
    if (!data?.jobMatchingDetail.jobAttributes) return [];

    return new JobAttributeList({
      data: data.jobMatchingDetail.jobAttributes
    }).attributes.map((attr) => ({
      ...attr,
      missing: attr.gapReason !== Abstract_Attribute.gapReason.met
    }));
  }, [data?.jobMatchingDetail.jobAttributes]);

  const jobAttributes = useMemo(() => {
    return showGapsOnly ? allJobAttributes.filter((attr) => attr.missing) : allJobAttributes;
  }, [allJobAttributes, showGapsOnly]);

  const toggleDescription = (show: boolean) => (e: MouseEvent) => {
    e.preventDefault();
    setShowFullDescription(show);
  };

  const handleGapsToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setShowGapsOnly(e.target.checked);
  };

  return {
    jobMatchingDetail: data?.jobMatchingDetail,
    jobAttributes,
    isLoading,
    error,
    showFullDescription,
    showGapsOnly,
    showPrintModal,
    handleOpenPrintModal,
    handleClosePrintModal,
    handleConfirmPrint,
    handleOpenJobDescriptionDialog: toggleDescription(true),
    handleCloseJobDescriptionDialog: toggleDescription(false),
    handleSwitchShowGaps: handleGapsToggle
  };
};
