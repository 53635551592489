import React from "react";
import { Table } from "./Table";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Tooltip } from "../Tooltip";

export class SimilarJobsTable extends Table {
  static columns = {
    name: {
      field: "name",
      label: "Job title"
    },
    similarity: {
      field: "similarity",
      label: "Similarity details and %"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    noRowsMessage: "No rows, yet"
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  calculateSimilarity(matching: number, total: number, otherTotal: number) {
    const percentage = (matching / total) * 100;
    return {
      value: percentage,
      display: `${total} → ${otherTotal} = ${matching} (${percentage.toFixed(0)}%)`
    };
  }
  onRowClick(params: any, e: any) {
    const { onRowClick } = this.props;
    if (onRowClick) {
      onRowClick(e, params.row);
    }
  }

  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === SimilarJobsTable.columns.name) {
          this.columns.push({
            field: "name",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1.8,
            valueGetter: (params: any) => params.row.name,
            cellRenderer: (params: any) => <div className='truncate'>{params.row.name}</div>
          });
        }
        if (c.type === SimilarJobsTable.columns.similarity) {
          this.columns.push({
            field: "similarity",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params: any) => {
              return this.calculateSimilarity(
                params.row.numberOfMatchingJobAttributes,
                params.row.numberOfJobAttributes,
                params.row.numberOfOtherJobsJobAttributes
              ).value;
            },
            renderCell: (params: any) => {
              const similarity = this.calculateSimilarity(
                params.row.numberOfMatchingJobAttributes,
                params.row.numberOfJobAttributes,
                params.row.numberOfOtherJobsJobAttributes
              );

              return (
                <Box display='flex' alignItems='center' gap={1} flex={1}>
                  <Typography variant='body2'>{similarity.display}</Typography>
                </Box>
              );
            }
          });
        }
      }
    });
  }
}
