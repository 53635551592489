import { JobDescriptionContainer } from "klayowebshared";
import { AppContext } from "../../common/AppContext";
import { AbstractDialog } from "../../components/AbstractDialog";
import { Dialog } from "../../components/dialogs/Dialog";

export class JobDescriptionDialog extends AbstractDialog {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
  }

  render() {
    const { theme, onClose, description } = this.props;

    return (
      <Dialog
        title='Job description'
        className='klaro-jobdescdialog'
        theme={theme}
        open={true}
        onClose={onClose}
        maxWidth='md'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: onClose
          }
        ]}
      >
        <JobDescriptionContainer height={null} text={description} />
      </Dialog>
    );
  }
}
